import { useState } from 'react'

export default function useHover() {
  let [hovering, setHovering] = useState(false)

  const hoverProps = {
    onMouseOver: () => setHovering(true),
    onMouseOut: () => setHovering(false),
  }

  return [hovering, hoverProps, setHovering]
}
