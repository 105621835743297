import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg'
import { breakpoints, colors, spacing } from '../config'
import { Detail } from './Typography'

function BackButton({ onClick }) {
  return (
    <BackContainer onClick={onClick}>
      <Icon />
      <Text>Back</Text>
    </BackContainer>
  )
}

BackButton.propTypes = {
  onClick: PropTypes.func,
}

const BackContainer = styled.div`
  ${breakpoints({
    display: ['flex', 'flex', 'none', 'none'],
  })};
  flex-direction: row;
  cursor: pointer;
  margin-right: ${spacing.xs}px;
`

const Icon = styled(ArrowRight)`
  height: 20px;
  transform: rotate(180deg);
  margin-right: ${spacing.xs}px;

  path {
    fill: ${colors.orange};
  }
`

const Text = styled(Detail)`
  color: ${colors.white};
`

export default BackButton
