import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../sagas'
import rootReducer from '../state'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const isDev = process.env.NODE_ENV !== 'production'

const sagaMiddleware = createSagaMiddleware()

const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false, // ignore redux-persist error
  }),
  sagaMiddleware,
]

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    devTools: isDev,
    middleware,
  })

  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  if (isDev && module.hot) {
    module.hot.accept('../state', () => store.replaceReducer(persistedReducer))
  }

  return { store, persistor }
}
