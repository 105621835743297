export default {
  purple: '#4b1430',
  green: '#0D3624',
  darkGreen: '#072B1C',
  black: '#2c2727',
  blue: '#103741',
  orange: '#ff492f',
  grey: '#f8f8f4',
  white: '#ffffff',

  // Non-style-guide colors
  lightText: '#414141',
  lightGrey: '#e5e5e5',
}
