import React, { useState } from 'react'
import { Body } from '../Typography'
import { colors, spacing } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { ReactComponent as Plus } from '../../assets/plus.svg'
import { useSelector } from 'react-redux'
import { selectCurrentArea } from '../../state/ui'
import breakpoints from '../../config/breakpoints'

/**
 * ShareButton will open native share if possible. Share current page url.
 *
 */
const ShareButton = ({ title }) => {
  const [text, setText] = useState('Share Article')
  const [copied, setCopied] = useState(false)
  const area = useSelector(selectCurrentArea)

  return (
    <VerticallyCentered
      bgColor={area?.color || colors.green}
      Ø
      copied={copied}
      onClick={() => shareAction(title, setText, setCopied)}
    >
      <Text copied={copied}>{text}</Text>
      <Icon copied={copied} />
    </VerticallyCentered>
  )
}

function shareAction(title, setText, setCopied) {
  if (navigator.share) {
    navigator
      .share({
        title: title,
        url: window.location.href,
      })
      .then(() => {
        console.log('Thanks for sharing!')
      })
      .catch(console.error)
  } else {
    setText('Copied Link')
    setCopied(true)
    navigator.clipboard.writeText(window.location.href)
    setTimeout(() => {
      setText('Share Article')
      setCopied(false)
    }, 2600)
  }
}

ShareButton.propTypes = {
  title: PropTypes.string.isRequired,
}

const VerticallyCentered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${props => (props.copied ? colors.grey : props.bgColor)};

  ${breakpoints({
    padding: [spacing.m, spacing.m, spacing.m, spacing.l],
  })};
`

const Text = styled(Body)`
  color: ${props => (props.copied ? colors.orange : colors.white)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Icon = styled(({ copied, ...props }) => <Plus {...props} />)`
  width: 30px;

  path {
    stroke: ${props => (props.copied ? colors.orange : colors.white)};
  }
`

export default ShareButton
