import React from 'react'
import { spacing, breakpoints } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

/**
 * Gallery for article. Display array of images
 *
 */
const Gallery = ({ content }) => {
  return (
    <Container>
      <HeaderImage src={content[0].image} />
      <ImageGrid>
        {content.slice(1).map((item, i) => (
          <ImageWrapper key={`image-${i}`}>
            <Image src={item.image} />
          </ImageWrapper>
        ))}
      </ImageGrid>
    </Container>
  )
}

Gallery.propTypes = {
  content: PropTypes.array.isRequired,
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const HeaderImage = styled.img`
  width: 100%;
  ${breakpoints({
    height: ['350px', '350px', '490px', '490px'],
  })};
  height: auto;
  width: auto;
  object-fit: cover;
`

const ImageWrapper = styled.div`
  position: relative;
  font-size: 0;
  margin-top: ${spacing.m}px;

  ${breakpoints({
    width: ['75%', '75%', 'calc(50% - 10px)'],
    paddingBottom: ['75%', '75%', '50%'],
  })}
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  ${breakpoints({
    justifyContent: ['center', 'center', 'space-between'],
    flexDirection: ['column', 'column', 'row'],
    alignItems: ['center', 'center'],
  })};
`

export default Gallery
