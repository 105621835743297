import React from 'react'
import { Body, H1 } from '../Typography'
import { colors, spacing, breakpoints } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import FindOutMore from '../FindOutMore'
import AreaItem from './AreaItem'
import { ReactComponent as Brand } from '../../assets/header-icon.svg'
import FadeIn from '../FadeIn'
import { Link } from 'react-router-dom'

/**
 * PageHeader header with background color, title, optional paragraph navigation row optional icons.
 *
 */
const PageHeader = ({
  background,
  title,
  paragraph,
  showMoreButton,
  navItems,
  selectedAreaId,
  showIcons,
}) => {
  return (
    <Wrapper background={background}>
      <FadeIn>
        <PageTitle>{title}</PageTitle>
      </FadeIn>
      {paragraph != null && (
        <FadeIn step={0.5}>
          <Paragraph>{paragraph}</Paragraph>
        </FadeIn>
      )}
      {showMoreButton != null && (
        <FadeIn step={1}>
          <ButtonWrapper>
            <Link to="/pages/about">
              <FindOutMore isLight />
            </Link>
          </ButtonWrapper>
        </FadeIn>
      )}
      {showIcons != null && (
        <FadeIn step={1.5}>
          <Icon />
        </FadeIn>
      )}
      {navItems != null && (
        <PageNav>
          {navItems.map((item, i) => (
            <FadeIn key={item.id} step={i}>
              <AreaItem
                item={item}
                isActive={selectedAreaId === item.id}
                key={item.slug}
              />
            </FadeIn>
          ))}
        </PageNav>
      )}
    </Wrapper>
  )
}

PageHeader.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  navItems: PropTypes.array,
  showIcons: PropTypes.bool,
  showMoreButton: PropTypes.bool,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 300px;
  height: auto;
  background-color: ${(props) => props.background || colors.purple};
`

const PageTitle = styled(H1)`
  color: ${colors.white};
  max-width: 900px;
  margin-top: ${spacing.mega}px;
  margin-left: ${spacing.mega}px;
  margin-right: ${spacing.mega}px;
  margin-bottom: ${spacing.xl}px;
  ${breakpoints({
    fontSize: ['40px', '40px', '50px', '50px'],
  })};
`

const Paragraph = styled(Body)`
  color: ${colors.white};
  max-width: 600px;
  ${breakpoints({
    display: ['none', 'none', 'block', 'block'],
  })};
  margin-bottom: ${spacing.xl}px;
`

const ButtonWrapper = styled.div`
  margin: ${spacing.xl}px auto;
`

const Icon = styled(Brand)`
  ${breakpoints({
    display: ['none', 'none', 'block', 'block'],
  })};
  margin-bottom: ${spacing.mega}px;
`
const PageNav = styled.div`
  ${breakpoints({
    borderTop: [
      '2px solid rgba(255,255,255,0.5)',
      '2px solid rgba(255,255,255,0.5)',
      'unset',
      'unset',
    ],
    width: ['100%', '100%', '500px', '500px'],
  })};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${spacing.mega}px;
  ${breakpoints({
    marginBottom: [spacing.m, spacing.m, spacing.mega, spacing.mega],
  })};
`

export default PageHeader
