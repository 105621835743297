import React from 'react'
import { Detail } from '../Typography'
import { spacing, colors } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

/**
 * Text content for an Article with text content
 *
 */
const Text = ({ text }) => {
  return <Content dangerouslySetInnerHTML={{ __html: clean(text) }} />
}

const INTERNAL_LINKS = [
  'http://wadiastaging.wpengine.com',
  'https://wadiastaging.wpengine.com',
  'http://wadia.wpengine.com',
  'https://wadia.wpengine.com',
]

// Fix internal links
function clean(text) {
  for (let i = 0; i < INTERNAL_LINKS.length; i++) {
    text = removeAll(text, INTERNAL_LINKS[i])
  }
  return text
}

// This is faster than a regex
function removeAll(text, term) {
  return text.split(term).join('')
}

Text.propTypes = {
  text: PropTypes.string.isRequired,
}

const Content = styled(Detail)`
  display: flex;
  flex-direction: column;
  margin: ${spacing.s}px;

  h4 {
    margin: 0;
  }

  a {
    color: ${colors.orange};
  }

  ul > li:before {
    content: '-';
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  ol > li {
    margin-left: 0;
    padding-left: 0.5em;
    margin-top: ${spacing.xs}px;
  }

  ul > li {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
    margin-top: ${spacing.xs}px;
  }
`

export default Text
