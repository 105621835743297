import { create } from 'apisauce'
import config from '../config'

export default class ApiClient {
  client = create({
    baseURL: config.api.baseURL,
    headers: {
      Accept: 'application/json',
    },
  })

  fetchArticles = () => {
    return this.client.get('article')
  }

  fetchPages = () => {
    return this.client.get('pages')
  }

  fetchAreas = () => {
    return this.client.get('area')
  }
}
