import { createSlice } from '@reduxjs/toolkit'
import { selectArea } from './areas'
import { REHYDRATE } from 'redux-persist'

const initialState = {
  menuOpen: false,
  searchOpen: false,
  currentArticleId: null,
  currentAreaId: null,
  keyOpen: false,
  showCallout: true,
}

export const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openMenu: state => {
      state.menuOpen = true
      state.searchOpen = false
    },
    closeMenu: state => {
      state.menuOpen = false
    },
    openSearch: state => {
      state.searchOpen = true
      state.menuOpen = false
      state.keyOpen = false
    },
    closeSearch: state => {
      state.searchOpen = false
    },
    currentArticleId: (state, action) => {
      state.currentArticleId = action.payload
    },
    currentAreaId: (state, action) => {
      state.currentAreaId = action.payload
    },
    openKey: state => {
      state.keyOpen = true
      state.searchOpen = false
      state.menuOpen = false
    },
    closeKey: state => {
      state.keyOpen = false
    },
    dismissCallout: state => {
      state.showCallout = false
    },
  },
  extraReducers: {
    [REHYDRATE]: (state, action) => {
      const rehydratedShowCallout = action.payload?.ui?.showCallout
      const showCallout = rehydratedShowCallout === false ? false : true

      return {
        ...initialState,
        showCallout,
      }
    },
  },
})

export const {
  openMenu,
  closeMenu,
  openSearch,
  closeSearch,
  currentArticleId,
  currentAreaId,
  openKey,
  closeKey,
  dismissCallout,
} = slice.actions

export const selectMenuOpen = ({ ui }) => ui.menuOpen
export const selectSearchOpen = ({ ui }) => ui.searchOpen
export const selectCurrentArticleId = ({ ui }) => ui.currentArticleId
export const selectCurrentAreaId = ({ ui }) => ui.currentAreaId
export const selectCurrentArea = state =>
  selectArea(state, state.ui.currentAreaId)
export const selectKeyOpen = ({ ui }) => ui.keyOpen
export const selectShowCallout = ({ ui }) => ui.showCallout

export default slice.reducer
