export default function urlFor(item) {
  if (!(typeof item === 'object')) {
    return null
  }

  if (item.type === 'link') {
    return item.to
  }

  if (item.type == null) {
    return null
  }

  if (item.type === 'article') {
    return `/articles/${item.slug}`
  }

  if (item.type === 'area') {
    return `/areas/${item.slug}`
  }

  if (item.type === 'page') {
    return `/pages/${item.slug}`
  }

  return null
}
