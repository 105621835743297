import styled from '@emotion/styled'
import React from 'react'
import { ReactComponent as CrossIcon } from '../../assets/cross.svg'
import { colors, spacing } from '../../config'
import { Detail } from '../Typography'
import { PropTypes } from 'prop-types'
import breakpoints from '../../config/breakpoints'

function ClearButton({ onClick }) {
  return (
    <Container onClick={onClick}>
      <ClearIcon />
      <Text>Clear</Text>
    </Container>
  )
}

ClearButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const Container = styled(Detail)`
  display: inline-flex;
  align-items: center;
  background-color: ${colors.grey};
  color: ${colors.orange};
  cursor: pointer;
  padding: ${spacing.xxs}px ${spacing.xs}px;

  &:hover {
    background-color: ${colors.grey}99;
  }
`

const ClearIcon = styled(CrossIcon)`
  ${breakpoints({
    marginRight: [0, 0, spacing.xxs],
  })}

  path {
    stroke: ${colors.orange};
  }
`

const Text = styled.span`
  ${breakpoints({
    display: ['none', 'none', 'block'],
  })}
`

export default ClearButton
