import React, { useEffect } from 'react'
import Home from './screens/Home'
import { withRouter } from 'react-router'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Area from './screens/Area'
import Article from './screens/Article'
import Page from './screens/Page'
import Error from './screens/Error'
import { useDispatch, useSelector } from 'react-redux'
import { fetch as fetchAreas } from './state/areas'
import { fetch as fetchArticles } from './state/articles'
import { fetch as fetchPages } from './state/pages'
import { selectKeyOpen, selectMenuOpen, selectSearchOpen } from './state/ui'
import { GlobalStyling } from './components/GlobalStyling'
import Menu from './components/Menu'
import SearchOverlay from './components/Search'
import { Helmet } from 'react-helmet'
import config from './config'
import Key from './components/Key'

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      setTimeout(function () {
        window.scrollTo(0, 0)
      }, 2)
    }
  }

  render() {
    return this.props.children
  }
}

const Scroll = withRouter(ScrollToTop)

export default () => {
  const dispatch = useDispatch()
  const menuOpen = useSelector(selectMenuOpen)
  const searchOpen = useSelector(selectSearchOpen)
  const keyOpen = useSelector(selectKeyOpen)

  useEffect(() => {
    dispatch(fetchArticles())
    dispatch(fetchPages())
    dispatch(fetchAreas())
  }, [dispatch])

  return (
    <div>
      <Helmet>
        <title>DIA | Damages in International Arbitration</title>
        <meta
          name="description"
          content="The International Council for Commercial Arbitration and the American Society of International Law have created a joint task force, the ICCA-ASIL Task Force on Damages in International Arbitration."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={config.domain} />
        <meta property="og:title" content="WADIA" />
        <meta
          property="og:description"
          content="The International Council for Commercial Arbitration and the American Society of International Law have created a joint task force, the ICCA-ASIL Task Force on Damages in International Arbitration."
        />
        {/* <meta property="og:image" content={`${config.domain}/og-image.png`} /> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={config.domain} />
        <meta property="twitter:title" content="WADIA" />
        <meta
          property="twitter:description"
          content="The International Council for Commercial Arbitration and the American Society of International Law have created a joint task force, the ICCA-ASIL Task Force on Damages in International Arbitration."
        />
        {/* <meta
          property="twitter:image"
          content={`${config.domain}/og-image.png`}
        /> */}
      </Helmet>
      <Router>
        <GlobalStyling />

        {menuOpen && <Menu />}
        {searchOpen && <SearchOverlay />}
        {keyOpen && <Key />}

        <Scroll>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/areas/:slug">
              <Area />
            </Route>
            <Route path="/articles/:slug">
              <Article />
            </Route>
            <Route path="/pages/:slug">
              <Page />
            </Route>
            <Route path="/404">
              <Error />
            </Route>
          </Switch>
        </Scroll>
      </Router>
    </div>
  )
}
