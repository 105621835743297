export const initialState = {
  threshold: null,
  screenWidth: null,
  elementWidth: null,
  panels: [],
}

export default function reducer(state, action) {
  switch (action.type) {
    case CLEAR_THRESHOLD:
      return {
        ...state,
        threshold: null,
        screenWidth: null,
        elementWidth: null,
      }

    case SET_THRESHOLD: {
      const { threshold, screenWidth } = action
      const { panels } = state
      if (threshold < panels.length && threshold > 0) {
        return {
          ...state,
          threshold,
          screenWidth,
        }
      } else {
        return state
      }
    }

    case SELECT_ITEM: {
      const { item, depth } = action
      const { panels } = state
      const panelsToItem = [...panels.slice(0, depth + 1)]

      const numChildren = item.children?.length || 0

      const newPanels =
        numChildren === 0
          ? panelsToItem
          : [
              ...panelsToItem,
              {
                key: item.key || `${item.type}-${item.id}`,
                title: item.acf.child_title,
                items: item.children,
                item,
              },
            ]

      return {
        ...state,
        panels: newPanels,
        threshold: null,
        screenWidth: null,
      }
    }

    case EXPAND_PANEL: {
      return {
        ...state,
        panels: [...state.panels.slice(0, action.depth + 1)],
      }
    }

    case SET_PANELS: {
      return {
        ...state,
        panels: action.panels,
      }
    }

    default:
      throw new Error()
  }
}

const CLEAR_THRESHOLD = 'CLEAR_THRESHOLD'
const SET_THRESHOLD = 'SET_THRESHOLD'
const SELECT_ITEM = 'SELECT_ITEM'
const EXPAND_PANEL = 'EXPAND_PANEL'
const SET_PANELS = 'SET_PANELS'

export const clearThreshold = () => ({ type: CLEAR_THRESHOLD })
export const setThreshold = (thresholdDepth, screenWidth) => ({
  type: SET_THRESHOLD,
  threshold: thresholdDepth,
  screenWidth,
})
export const selectItem = (item, depth) => ({ type: SELECT_ITEM, item, depth })
export const expandPanelAtDepth = depth => ({ type: EXPAND_PANEL, depth })
export const setPanels = panels => ({ type: SET_PANELS, panels })
