import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { colors, breakpoints } from '../config'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NavBar from '../components/NavBar'
import Loading from '../components/Loading'
import { selectPageBySlug, selectLoaded } from '../state/pages'
import PageHeader from '../components/PageHeader'
import Text from '../components/Sections/Text'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'

const Page = () => {
  let { slug } = useParams()
  const page = useSelector(state => selectPageBySlug(state, slug))
  const isLoaded = useSelector(selectLoaded)

  let [is404, setIs404] = useState(false)

  useEffect(() => {
    setIs404(page == null && isLoaded)
  }, [page, isLoaded])

  if (is404) {
    return <Redirect to={'/404'} />
  }

  if (page == null) {
    return <Loading />
  }

  return (
    <div>
      <Helmet>
        <title>DIA | {page.title.rendered}</title>
      </Helmet>
      <NavBar background={colors.purple} />
      <PageHeader
        title={page.title.rendered}
        showIcons
        background={colors.purple}
      />
      <Container>
        <Text text={page.content.rendered} />
      </Container>
    </div>
  )
}

const Container = styled.div`
  backgroundcolor: ${colors.white};
  display: flex;
  flex-direction: row;
  ${breakpoints({
    padding: ['0', '0', '0 120px'],
  })};
`

export default Page
