import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { colors, spacing, breakpoints } from '../config'
import { Helmet } from 'react-helmet'
import { Detail, Mega } from '../components/Typography'
import NavBar from '../components/NavBar'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { currentAreaId, currentArticleId } from '../state/ui'

const Error = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(currentArticleId(null))
    dispatch(currentAreaId(null))
  })

  return (
    <div>
      <Helmet>
        <title>404 | Page not found</title>
      </Helmet>
      <NavBar />
      <Container>
        <Title>404</Title>
        <Message>This page doesn't exist.</Message>
        <Button to={'/'}>
          <Label>Go to homepage</Label>
        </Button>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`

const Title = styled(Mega)`
  color: ${colors.green};
`

const Message = styled(Detail)`
  text-align: center;
  color: ${colors.green};
  ${breakpoints({
    width: ['90%', '80%', '60%', '60%'],
  })};
`

const Button = styled(Link)`
  color: ${colors.green};
  text-decoration: none;
  margin-top: ${spacing.l}px;
  padding: ${spacing.tiny}px ${spacing.xxs}px;

  &:hover {
    border-bottom: 1px solid ${colors.orange};
  }
`

const Label = styled(Detail)`
  color: ${colors.orange};
`

export default Error
