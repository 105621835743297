export default {
  /**
   * 8px
   */
  tiny: 4,

  /**
   * 8px
   */
  xxs: 8,

  /**
   * 12px
   */
  xs: 12,

  /**
   * 16px
   */
  s: 16,

  /**
   * 20px
   */
  m: 20,

  /**
   * 24px
   */
  l: 24,

  /**
   * 32px
   */
  xl: 32,

  /**
   * 40px
   */
  xxl: 40,

  /**
   * 60px
   */
  mega: 60,
}
