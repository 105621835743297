import React from 'react'
import { Body } from '../Typography'
import { colors, spacing, breakpoints } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import urlFor from '../../helpers/urlFor'

/**
 * AreaItem used in the page header
 */
const AreaItem = ({ item, isActive }) => {
  return (
    <Wrapper to={urlFor(item)}>
      <Line isActive={isActive} />
      <Text isActive={isActive}>{item.slug}</Text>
    </Wrapper>
  )
}

AreaItem.propTypes = {
  isActive: PropTypes.bool,
  item: PropTypes.object.isRequired,
}

const Line = styled.div`
  ${breakpoints({
    display: ['none', 'none', 'flex', 'flex'],
  })};
  width: 100%;
  height: 2px;
  background-color: ${props =>
    props.isActive ? colors.orange : 'rgba(255,255,255,0.5)'};
  ${props => (props.isActive ? 'display: flex !important' : 'display: none')};
`

const Wrapper = styled(Link)`
  ${breakpoints({
    width: ['auto', 'auto', '120px', '120px'],
    margin: ['0 20px', '0 20px', '25px', '25px'],
  })};
  max-width: 100%;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
`

const Text = styled(({ isActive, ...props }) => <Body {...props} />)`
  color: ${props => (props.isActive ? colors.orange : 'rgba(255,255,255,0.5)')};
  transition: color 0.15s linear;
  ${breakpoints({
    paddingLeft: ['0', '0', spacing.m, spacing.m],
    paddingRight: ['0', '0', spacing.m, spacing.m],
  })};
  padding-top: ${spacing.m}px;
  text-transform: capitalize;
  text-decoration: none;
`

export default AreaItem
