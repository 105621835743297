import React from 'react'
import { Body } from '../Typography'
import { colors, spacing, breakpoints } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg'
import useHover from '../../helpers/useHover'
import { useSelector } from 'react-redux'
import { selectCurrentArea } from '../../state/ui'
import urlFor from '../../helpers/urlFor'
import { Link } from 'react-router-dom'

/**
 * OverviewButton link with title and arrow.
 *
 */
const OverviewButton = () => {
  let [hovering, hoverProps] = useHover()
  const area = useSelector(selectCurrentArea)
  const areaUrl = urlFor(area)

  return (
    <OverviewLink to={areaUrl} isActive={hovering} {...hoverProps}>
      <VerticallyCentered>
        <HoverIcon isActive={hovering} />
        <Text isActive={hovering}>Back to Overview</Text>
      </VerticallyCentered>
    </OverviewLink>
  )
}

OverviewButton.propTypes = {
  /**
   * Manually control active state
   */
  isActive: PropTypes.bool,
}

const OverviewLink = styled(({ isActive, ...props }) =>
  props.to == null ? <div {...props} /> : <Link {...props} />
)`
  text-decoration: none;
  width: 230px;
  background-color: ${colors.white};
  z-index: 3;
  position: absolute;
  top: calc(50% - 32px);
  left: -83px;
  transform: rotate(-90deg);

  &:hover {
    background-color: ${colors.grey};
  }

  ${breakpoints({
    display: ['none', 'none', 'inline-block', 'inline-block'],
  })};
`

const VerticallyCentered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.m}px;
`

const Text = styled(({ isActive, ...props }) => <Body {...props} />)`
  color: ${props => (props.isActive ? colors.orange : colors.black)};
  transition: color 0.15s linear;
  white-space: nowrap;
`

const HoverIcon = styled(({ isActive, ...props }) => <ArrowRight {...props} />)`
  width: 30px;
  transform: rotate(-90deg);

  path {
    fill: ${colors.orange};
    transition: color 0.15s linear;
  }
`

export default OverviewButton
