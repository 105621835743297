import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { H3, Body } from './Typography'
import { colors, spacing } from '../config'
import useHover from '../helpers/useHover'
import { breakpoints } from '../config'

const ArticleCard = ({
  title,
  description,
  numThemes,
  isSelected,
  isDisabled,
  onReadMore,
  onSeeThemes,
  isLight,
}) => {
  let [hovering, hoverProps, setHovering] = useHover()
  let [seeThemesHovering, seeThemesHoverProps, setHoveringThemes] = useHover()
  const showDetail = !isSelected && !isDisabled
  const theme = isLight ? lightTheme : darkTheme

  return (
    <Wrapper
      isSelected={isSelected}
      onClick={() => {
        if (isSelected || (!isDisabled && numThemes === 0)) {
          onReadMore()
        } else {
          onSeeThemes()
        }
      }}
      {...seeThemesHoverProps}
    >
      <BarArea fullWidth={!showDetail}>
        {isSelected && <Bar small />}
        {!isDisabled && (
          <BarThick
            isSelected={isSelected || seeThemesHovering}
            theme={theme}
          />
        )}
        <Bar isSelected={isSelected || seeThemesHovering} theme={theme} />
      </BarArea>
      <Content>
        <Title disabled={isDisabled} theme={theme} hovering={hovering}>
          {title}
        </Title>
        {showDetail && (
          <Description theme={theme} hovering={hovering}>
            {description}
          </Description>
        )}
        {!isDisabled && (
          <Actions>
            <ReadLink
              onClick={onReadMore}
              hovering={
                (isSelected && seeThemesHovering) ||
                hovering ||
                (numThemes === 0 && seeThemesHovering)
              }
              theme={theme}
              {...hoverProps}
            >
              Read More
              <Underline
                theme={theme}
                hovering={
                  (isSelected && seeThemesHovering) ||
                  hovering ||
                  (numThemes === 0 && seeThemesHovering)
                }
              />
            </ReadLink>

            {!isSelected && numThemes > 0 && onSeeThemes != null && (
              <ExpandButton
                onClick={(e) => {
                  e.stopPropagation()
                  onSeeThemes(e)

                  // The card collapsing causes the mouse to now be outside of the card
                  // without the card receiving `onMouseOut`
                  setHovering(false)
                  setHoveringThemes(false)
                }}
                {...seeThemesHoverProps}
                hovering={seeThemesHovering && !hovering}
              >
                See {numThemes} Themes +
              </ExpandButton>
            )}
          </Actions>
        )}
      </Content>
    </Wrapper>
  )
}

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  numThemes: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onReadMore: PropTypes.func.isRequired,
  onSeeThemes: PropTypes.func,
  isLight: PropTypes.bool,
}

const GUTTER_WIDTH = spacing.xxl
const lightTheme = {
  readLink: 'rgba(255, 255, 255, 0.5)',
  readLinkHover: colors.orange,
  title: colors.white,
  titleHover: colors.black,
  titleDisabled: 'rgba(255, 255, 255, 0.5)',
  description: colors.white,
  descriptionHover: colors.black,
  bar: 'rgba(255, 255, 255, 0.1)',
  barThick: colors.white,
}
const darkTheme = {
  readLink: 'rgba(0, 0, 0, 0.5)',
  readLinkHover: colors.orange,
  title: colors.black,
  titleHover: colors.black,
  titleDisabled: 'rgba(0, 0, 0, 0.5)',
  description: colors.lightText,
  descriptionHover: colors.black,
  bar: 'rgba(0, 0, 0, 0.1)',
  barThick: colors.black,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.isSelected ? colors.grey : 'none')};
  cursor: pointer;

  &:hover {
    background-color: ${colors.grey};
  }

  ${breakpoints({
    width: ['100%', '100%', '100%'],
  })}
`

const Content = styled.div`
  margin-left: ${GUTTER_WIDTH}px;
  padding-right: ${spacing.xs}px;
`

const BarArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 3px;
  margin-left: ${(props) => (props.fullWidth ? 0 : GUTTER_WIDTH)}px;

  ${breakpoints({
    marginRight: [spacing.xl, spacing.xl, 0],
  })}
`

const BarThick = styled.span`
  height: 3px;
  width: 50px;
  background-color: ${(props) =>
    props.isSelected ? colors.orange : props.theme.barThick};
`

const Bar = styled.span`
  height: 1px;
  background-color: ${(props) =>
    props.isSelected ? colors.orange : props.theme.bar};
  margin-bottom: ${spacing.xl}px;

  width: ${(props) => (props.small ? `${GUTTER_WIDTH}px` : '100%')};
`

const Title = styled(H3)`
  margin: ${spacing.xl}px 0;
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.titleDisabled
    }

    return props.hovering ? props.theme.titlehover : props.theme.title
  }};
`

const Description = styled(Body)`
  margin-bottom: ${spacing.l}px;
  color: ${(props) =>
    props.hovering ? props.theme.descriptionHover : props.theme.description};
`

const Actions = styled.div`
  display: flex;
  margin-bottom: ${spacing.xxs}px;
`

const ReadLink = styled(Body)`
  cursor: pointer;
  font-weight: bold;
  margin-right: ${spacing.xxl}px;
  transition: color 0.15s linear;
  color: ${(props) =>
    props.hovering ? props.theme.readLinkHover : props.theme.readLink};

  &:hover {
    color: ${(props) => props.theme.readLinkHover};
  }
`

const Underline = styled.div`
  width: ${(props) => (props.hovering ? '100%' : '35px')};
  height: 2px;
  background-color: ${(props) =>
    props.hovering ? colors.orange : props.theme.readLink};
  margin-top: ${spacing.xs}px;
  transition: width 0.15s linear, color 0.15s linear;
`

const ExpandButton = styled(Body)`
  cursor: pointer;
  font-weight: bold;
  color: ${(props) => (props.hovering ? 'rgba(0, 0, 0, 0.66)' : null)};

  &:hover {
    color: rgba(0, 0, 0, 0.66);
  }
`

export default ArticleCard
