import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactFlow, {
  removeElements,
  ReactFlowProvider,
  addEdge,
  Controls,
  MiniMap,
  Background,
  isNode,
} from 'react-flow-renderer'
import dagre from 'dagre'

import { generateInitialElements } from './initial-elements'

const onLoad = (reactFlowInstance) => {
  reactFlowInstance.fitView()
}

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

export const LayoutFlow = ({ data }) => {
  const initialElements = data
  const articleData = useSelector((state) => state.articles.data)
  const [elements, setElements] = useState(initialElements)
  const dispatch = useDispatch()

  const getLayoutedElements = (elements, direction = 'TB') => {
    dagreGraph.setGraph({ rankdir: direction, ranker: 'tight-tree' })
    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: 150, height: 50 })
      } else {
        dagreGraph.setEdge(el.source, el.target)
      }
    })
    dagre.layout(dagreGraph)
    return elements.map((el, i) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id)
        el.style =
          el.type == 'input'
            ? { background: '#103741', borderColor: '#103741' }
            : { background: '#4b1430', borderColor: '#4b1430' }
        el.targetPosition = 'top'
        el.sourcePosition = 'bottom'
        el.position = {
          x: nodeWithPosition.x + Math.random() / 1000,
          y: nodeWithPosition.y,
        }
      }
      return el
    })
  }

  const layoutedElements = getLayoutedElements(elements)

  const onConnect = (params) =>
    setElements((els) =>
      addEdge({ ...params, type: 'smoothstep', animated: true }, els)
    )
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els))
  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction)
      setElements(layoutedElements)
    },
    [elements]
  )
  if (!data) {
    return <>Loading...</>
  }
  return (
    <ReactFlowProvider>
      <ReactFlow
        elements={elements}
        onElementsRemove={onElementsRemove}
        onConnect={onConnect}
        onLoad={onLoad}
        snapToGrid={true}
        paneMoveable={true}
        nodesDraggable={false}
        zoomOnScroll={false}
        snapGrid={[15, 15]}
        onConnect={onConnect}
        connectionLineType="smoothstep"
      />
      <MiniMap
        nodeStrokeColor={(n) => {
          if (n.type === 'input') return '#103741'
          if (n.type === 'output') return '#4b1430'
        }}
        nodeColor={(n) => {
          if (n.type === 'input') return '#103741';
          return '#4b1430';
        }}
      />
      <Controls showInteractive={false} />
      <Background color="#aaa" gap={16} />
    </ReactFlowProvider>
  )
}
