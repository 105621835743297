import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAreas } from '../../state/areas'
import { closeKey } from '../../state/ui'
import styled from '@emotion/styled'
import { colors } from '../../config'
import { BodyBold, Body, H4 } from '../Typography'
import useKeydownHandler, { ESC_KEY } from '../../helpers/useKeydownHandler'
import Modal from 'react-modal'

import { LayoutFlow } from './LayoutFlow'
import NavLeft from '../NavBar/NavLeft'
import spacing from '../../config/spacing'
import CloseButton from '../CloseButton'

import { generateInitialElements } from './initial-elements'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    maxWidth: 450,
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: `rgba(0, 0, 0, 0.75)`,
  },
}

const KeyContainer = () => {
  const dispatch = useDispatch()
  const areas = useSelector(selectAreas)
  const [selectedArea, setSelectedArea] = useState(() => {
    return areas?.length > 0 ? areas[0] : null
  })

  const [modalOpen, setModelOpen] = useState(true)

  useKeydownHandler(ESC_KEY, () => dispatch(closeKey()))
  const articleData = useSelector((state) => state.articles.data)
  const initialElements = generateInitialElements(articleData)

  return (
    <Container>
      <NavArea>
        <NavLeft inverted />
        <Middle></Middle>
        <CloseButton onClick={() => dispatch(closeKey())}>Close</CloseButton>
      </NavArea>
      <ChartContainer>
        {initialElements.map((chartData, i) => {
          return (
            <Chart key={`chart${i}`}>
              <LayoutFlow data={chartData} />
            </Chart>
          )
        })}
      </ChartContainer>

      <Modal
        isOpen={modalOpen}
        onRequestClose={() => {
          setModelOpen(!modalOpen)
        }}
        style={customStyles}
        contentLabel="Key Modal"
      >
        <Title>Key</Title>
        <Body>This is the overall key of the content within DIA</Body>
        <Body>
          Click & drag in order to explore the Key. To find more information on
          a subject, click the title node.
        </Body>
        <Body>Use the + and - icons to zoom in and out of the map.</Body>
        <Body>Scroll to view the various topic keys.</Body>
        <OkayBtn
          onClick={() => {
            setModelOpen(false)
          }}
        >
          <BodyBold>Continue</BodyBold>
        </OkayBtn>
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: white;
`

const NavArea = styled.div`
  display: flex;
  align-items: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const Middle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-right: 130px;
  align-items: flex-end;
`
const Areas = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
`

const Area = styled(BodyBold)`
  border-bottom: 2px solid
    ${(props) => (props.selected ? colors.orange : colors.black + '44')};
  cursor: pointer;
  color: ${(props) => (props.selected ? colors.orange : colors.black + '88')};
  padding: ${spacing.xs}px;

  &:hover {
    color: ${colors.orange};
    border-bottom: 2px solid ${colors.orange};
  }

  margin-right: ${spacing.mega}px;
  &:last-of-type {
    margin-right: 0;
  }
`

const ChartContainer = styled.div`
  overflow: auto;
`
const Chart = styled.div`
  height: 85vh;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
  &:hover {
    cursor: grab;
  }
  .react-flow__node {
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }
  .react-flow__node-input .react-flow__handle {
    background-color: ${colors.blue}!important;
  }

  .react-flow__node-output .react-flow__handle {
    background-color: ${colors.purple}!important;
  }
`

const OkayBtn = styled.div`
  margin-top: 25px;
  cursor: pointer;
  ${BodyBold} {
    padding-bottom: 5px;
    width: 100px;
    margin: 0 auto;
    border-bottom: 2px solid ${colors.orange};
  }
`

const Title = styled(H4)`
  margin-bottom: 20px;
`

export default KeyContainer
