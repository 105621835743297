import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectFlatArticles } from '../../state/articles'

const searchOptions = {
  shouldSort: true,
  threshold: 0.2,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'title.rendered',
    'acf.content.anchor_title',
    'acf.content.text',
    'acf.content.quote',
    'acf.content.items.title',
    'acf.content.author',
  ],
}

export default function () {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const data = useSelector(selectFlatArticles)

  useEffect(() => {
    const filteredData = data.filter((item) => item.acf.content)
    let term = query
    if (term.length === 0) {
      setResults([])
      return
    }

    if (term.length > 32) {
      term = query.substr(0, 31)
    }
    if (filteredData.length > 0) {
      const fuse = new Fuse(filteredData, searchOptions)
      setResults(fuse.search(term))
    }
  }, [query, data])

  return [query, setQuery, results]
}
