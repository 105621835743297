import React from 'react'
import PropTypes from 'prop-types'
import { closeKey, closeMenu, openMenu } from '../../state/ui'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { breakpoints, colors, spacing } from '../../config'
import { ReactComponent as BurgerIcon } from '../../assets/burger.svg'
import { ReactComponent as BrandLogo } from '../../assets/logo.svg'
import { Link } from 'react-router-dom'

const NavLeft = ({ inverted }) => {
  const dispatch = useDispatch()

  return (
    <BurgerWrapper>
      <Burger onClick={() => dispatch(openMenu())} inverted={inverted} />
      <PlainLink
        to={'/'}
        onClick={() => {
          dispatch(closeKey())
          dispatch(closeMenu())
        }}
      >
        <Logo inverted={inverted} />
      </PlainLink>
    </BurgerWrapper>
  )
}

const BurgerWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacing.xxl}px;
  width: 310px;
  justify-content: start;

  line {
    stroke: ${props => (props.inverted ? colors.black : colors.white)};
  }

  ${breakpoints({
    borderRight: [
      'none',
      'none',
      '1px solid rgba(255, 255, 255, 0.5)',
      '1px solid rgba(255, 255, 255, 0.5)',
    ],
  })};
`

const Burger = styled(({ inverted, ...props }) => <BurgerIcon {...props} />)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: ${spacing.xxl}px;

  &:hover {
    opacity: 0.8;
  }

  line {
    stroke: ${props => (props.inverted ? colors.black : colors.white)};
  }
`

const Logo = styled(({ inverted, ...props }) => <BrandLogo {...props} />)`
  display: flex;
  height: 36px;
  width: 146px;

  text {
    fill: ${props => (props.inverted ? colors.black : colors.white)};
  }

  path {
    stroke: ${props => (props.inverted ? colors.black : colors.white)};
    fill: ${props => (props.inverted ? colors.black : colors.white)};
  }

  circle {
    fill: ${props => (props.inverted ? colors.black : colors.white)};
  }
`

const PlainLink = styled(Link)`
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`

NavLeft.propTypes = {
  inverted: PropTypes.bool,
}

export default NavLeft
