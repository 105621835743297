import { put, call } from 'redux-saga/effects'
import { fetchSuccess, fetchFail } from '../state/pages'

export default function* fetchPages(api) {
  const response = yield call(api.fetchPages)

  if (response.ok) {
    yield put(fetchSuccess(response.data))
  } else {
    yield put(fetchFail(response))
  }
}
