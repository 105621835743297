import React from 'react'
import { Body } from '../Typography'
import { breakpoints, colors, spacing } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg'
import useHover from '../../helpers/useHover'

/**
 * SectionLink link with title and arrow.
 *
 * Hover/active state can be manually controlled by passing a boolean for `isActive` prop.
 */
const SectionLink = ({ isActive, title }) => {
  let [hovering, hoverProps] = useHover()

  // If isActive prop is not supplied, handle active state automtically
  const externallyControlled = isActive != null
  const internalActive = externallyControlled ? isActive : hovering

  if (externallyControlled) {
    hoverProps = null
  }

  return (
    <Wrapper href={`#${title}`} isActive={internalActive} {...hoverProps}>
      <VerticallyCentered>
        <Text isActive={internalActive}>{title}</Text>
        <HoverIcon isActive={internalActive} />
      </VerticallyCentered>
    </Wrapper>
  )
}

SectionLink.propTypes = {
  /**
   * Manually control active state
   */
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

const Wrapper = styled.a`
  display: inline-block;
  background-color: ${props => (props.isActive ? colors.grey : colors.white)};
  border-bottom: 1px solid ${colors.grey};
  text-decoration: none;

  &:hover {
    background-color: ${colors.grey};
  }
`

const VerticallyCentered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${breakpoints({
    margin: [spacing.m, spacing.m, spacing.m, spacing.l],
  })};
`

const Text = styled(({ isActive, ...props }) => <Body {...props} />)`
  color: ${props => (props.isActive ? colors.orange : colors.black)};
  transition: color 0.15s linear;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${breakpoints({
    marginRight: [0, 0, 0, spacing.m, spacing.l],
  })};
`

const HoverIcon = styled(({ isActive, ...props }) => <ArrowRight {...props} />)`
  width: 30px;
  transform: rotate(90deg);

  path {
    fill: ${props => (props.isActive ? colors.orange : colors.black)};
    transition: color 0.15s linear;
  }
`

export default SectionLink
