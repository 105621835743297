import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as CloseIcon } from '../../assets/menu-close.svg'
import { colors, spacing, breakpoints } from '../../config'
import BackButton from '../BackButton'

function Header({ onClose, collapsed, mobileOnly, onBack, showBack }) {
  return (
    <Row mobileOnly={mobileOnly}>
      <Left>
        <CloseButton onClick={onClose} collapsed={collapsed} />
        {!collapsed && <BrandIcon />}
      </Left>
      {showBack && (
        <BackWrapper onClick={onBack}>
          <BackButton />
        </BackWrapper>
      )}
    </Row>
  )
}

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  showBack: PropTypes.bool,
}

const Row = styled.div`
  ${props => {
    if (props.mobileOnly) {
      return breakpoints({
        display: ['flex', 'flex', 'none', 'none'],
      })
    }

    return 'display: flex'
  }};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${breakpoints({
    width: ['80%', '80%', '100%'],
    marginTop: ['-7px', '-7px', '-23px', '-23px'],
    marginLeft: ['20px', '20px', '-14px', '-14px'],
  })};
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  ${breakpoints({
    width: [240, 280, 310],
  })}
`

const CloseButton = styled(CloseIcon)`
  width: 30px;
  height: 30px;
  ${props => {
    const desktop = props.collapsed ? 0 : `${spacing.xxl}px`
    const mobile = `46px`

    return breakpoints({
      marginRight: [mobile, mobile, desktop, desktop],
    })
  }};
  cursor: pointer;

  line {
    stroke: ${colors.white};
  }

  &:hover line {
    stroke: ${colors.white}88;
  }
`

const BrandIcon = styled(Logo)`
  height: 36px;
  width: 146px;
`

const BackWrapper = styled.div`
  padding-right: ${spacing.m}px;
`

export default Header
