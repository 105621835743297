import React from 'react'
import { colors, breakpoints, spacing } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { H3 } from '../Typography'
import ShareButton from './ShareButton'
import ConnectedList from '../Sections/ConnectedList'
import Quote from '../Sections/Quote'
import Gallery from '../Sections/Gallery'
import Text from '../Sections/Text'
import FadeIn from '../FadeIn'

/**
 * ArticleBody main article content.
 *
 */
const ArticleBody = ({ article }) => {
  return (
    <Content>
      {article.acf.content != null &&
        article.acf.content &&
        article.acf.content.map((section, i) => (
          <Section step={1} id={section.anchor_title} key={`section-${i}`}>
            {renderSection(section)}
          </Section>
        ))}
      {article.acf.content == null ||
        (article.acf.content === false && (
          <ContentError>
            <ErrorText>No content in this article</ErrorText>
          </ContentError>
        ))}
      <ShareWrapper>
        <ShareButton title={article.title.rendered} />
      </ShareWrapper>
    </Content>
  )
}

function renderSection(section) {
  switch (section.acf_fc_layout) {
    case 'gallery':
      return <Gallery content={section.images} />
    case 'text':
      return <Text text={section.text} />
    case 'connected_list':
      return <ConnectedList content={section.items} />
    case 'quote':
      return <Quote quote={section.quote} author={section.author} />
    default:
      return <ErrorText>Unable to display content</ErrorText>
  }
}

ArticleBody.propTypes = {
  article: PropTypes.object.isRequired,
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  ${breakpoints({
    maxWidth: ['100%', '100%', '80%', '70%', '60%'],
  })}
`

const Section = styled(FadeIn)`
  /* margin-top: 50px; */
`

const ErrorText = styled(H3)`
  text-align: center;
  margin: ${spacing.xl}px;
`
const ContentError = styled(H3)`
  height: 30vh;
`

const ShareWrapper = styled.div`
  margin: ${spacing.xl}px;
  ${breakpoints({
    display: ['block', 'block', 'none', 'none'],
  })};
`

export default ArticleBody
