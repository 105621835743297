import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import AreaCard from '../components/AreaCard'
import { breakpoints, spacing } from '../config'
import { useDispatch, useSelector } from 'react-redux'
import { selectAreas } from '../state/areas'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { currentAreaId, currentArticleId } from '../state/ui'
import FadeIn from '../components/FadeIn'

function Home() {
  const areas = useSelector(selectAreas)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(currentArticleId(null))
    dispatch(currentAreaId(null))
  }, [dispatch])

  return (
    <div>
      <NavBar />
      <PageHeader
        title={'Damages in International Arbitration'}
        paragraph={
          'The International Council for Commercial Arbitration and the American Society of International Law have created a joint task force, the ICCA-ASIL Task Force on Damages in International Arbitration. '
        }
        showMoreButton
        showIcons
      />
      <Row>
        {areas.map((area, i) => (
          <FadeIn
            css={css`
              display: flex;
              ${breakpoints({
                width: ['100%', '100%', '100%', '100%', '33.3%', '33.3%'],
                padding: [
                  0,
                  0,
                  `0 ${spacing.l}px`,
                  `0 ${spacing.m}px`,
                  `0 ${spacing.m}px`,
                ],
              })}
            `}
            step={i}
            key={area.id}
          >
            <AreaCard id={area.id} />
          </FadeIn>
        ))}
      </Row>
    </div>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${breakpoints({
    marginLeft: [null, null, spacing.mega],
    marginRight: [null, null, spacing.mega],
    flexDirection: ['column', 'row', 'row', 'row'],
    alignItems: ['center', 'center'],
  })}
`

export default Home
