import styled from '@emotion/styled'
import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import MenuPanel from './MenuPanel'
import useWindowSize from '../../helpers/useWindowSize'
import useElementSize from '../../helpers/useElementSize'
import reducer, {
  clearThreshold,
  expandPanelAtDepth,
  initialState,
  selectItem,
  setPanels,
  setThreshold,
} from './reducer'
import { breakpoints } from '../../config'
import { closeMenu } from '../../state/ui'
import { useDispatch, useSelector } from 'react-redux'
import useKeydownHandler, { ESC_KEY } from '../../helpers/useKeydownHandler'
import { selectLoading } from '../../state'
import { selectAreasWithArticles } from '../../state/areas'
import { selectArticlesByArea } from '../../state/articles'

function Menu() {
  const dispatch = useDispatch()
  const [currentArea, setCurrentArea] = useState(null)

  const onClose = () => {
    dispatch(closeMenu())
  }

  useKeydownHandler(ESC_KEY, onClose)

  /**
   * Can 'focus' on either the start or end of the menu.
   * If the menu content is wider than the screen and we're not on mobile,
   * we'll start to collapse MenuPanels at the non-focused side
   */
  const { width: screenWidth } = useWindowSize()
  const [{ width: elementWidth }, elementRef] = useElementSize()

  // Threshold is the number of panels at which collapse is required
  // allows us to have as many panels as possible non-collapsed
  const [state, menuDispatch] = useReducer(reducer, initialState)
  const { threshold, screenWidth: thresholdScreenWidth, panels } = state
  const requiresCollapse =
    elementWidth > screenWidth ||
    (threshold != null && threshold < panels.length)
  const isLoading = useSelector(selectLoading)

  useLayoutEffect(() => {
    if (!requiresCollapse) {
      menuDispatch(clearThreshold())
      return
    }

    if (thresholdScreenWidth != null && thresholdScreenWidth !== screenWidth) {
      menuDispatch(clearThreshold())
      return
    }

    if (threshold == null) {
      menuDispatch(setThreshold(panels.length - 1, screenWidth))
    } else if (elementWidth > screenWidth) {
      menuDispatch(setThreshold(threshold - 1, screenWidth))
    }

    // We explicitly only want to recalculate collapsed panels when the element width changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementWidth])

  function shouldCollapse(depth) {
    if (!requiresCollapse || threshold == null) {
      return false
    }

    return depth < panels.length - threshold
  }

  const areas = useSelector(selectAreasWithArticles)
  const articlesByArea = useSelector(selectArticlesByArea)

  // Add areas panel when loaded
  useEffect(() => {
    if (
      panels.length === 0 &&
      !isLoading &&
      areas.length > 0 &&
      articlesByArea != null
    ) {
      menuDispatch(
        setPanels([
          {
            title: 'Sections',
            items: [
              {
                title: {
                  rendered: 'Home',
                },
                type: 'link',
                to: '/',
                key: 'home',
              },
              ...areas,
            ],
          },
        ])
      )
    }
  }, [panels, areas, articlesByArea, isLoading])

  return (
    <MenuContainer>
      <ScrollContent ref={elementRef}>
        {panels.map((panel, depth) => (
          <MenuPanel
            area={currentArea}
            HeaderComponent={
              <Header
                onClose={onClose}
                collapsed={shouldCollapse(depth)}
                mobileOnly={depth > 0}
                showBack={depth > 0}
                onBack={() => menuDispatch(expandPanelAtDepth(depth - 1))}
              />
            }
            FooterComponent={depth === 0 && Footer}
            key={`panel-${depth}`}
            title={panel.title}
            items={panel.items}
            depth={depth}
            onItemClick={item => {
              if (item.type === 'area') {
                setCurrentArea(item)
              }

              menuDispatch(selectItem(item, depth))

              const shouldDismiss = (item.children?.length || 0) === 0

              if (shouldDismiss) {
                onClose()
              }
            }}
            selectedItem={panels[depth + 1]}
            collapsed={shouldCollapse(depth)}
            onExpand={() => menuDispatch(expandPanelAtDepth(depth))}
          />
        ))}
      </ScrollContent>
    </MenuContainer>
  )
}

const MenuContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`

const ScrollContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  & > div {
    ${breakpoints({
      display: ['none', 'none', 'flex'],
    })};
  }

  & > div.expanded:last-child {
    display: flex;
    flex: 1;
  }
`

export default Menu
