import React from 'react'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors } from '../config'

const styles = {
  base: css`
    color: ${colors.black};
    padding: 0;
    margin: 0;
  `,
  body: css`
    font-family: DMSans;
    font-weight: 400;
  `,
  heading: css`
    font-family: PTSerif;
  `,
}

export const Mega = styled.span`
  ${styles.base};
  ${styles.heading};
  font-size: 220px;
  font-weight: bold;
`

export const H1 = styled.h1`
  ${styles.base};
  ${styles.heading};
  font-size: 50px;
  font-weight: bold;
`

export const H2 = styled.h2`
  ${styles.base};
  ${styles.heading};
  font-size: 37px;
`

export const H3 = styled.h3`
  ${styles.base};
  ${styles.heading};
  font-size: 25px;
`

export const H4 = styled.h4`
  ${styles.base};
  ${styles.heading};
  font-size: 22px;
`

export const Title = styled.p`
  ${styles.base};
  ${styles.heading};
  font-size: 18px;
`

export const Body = styled.p`
  ${styles.base};
  ${styles.body};
  font-size: 18px;
`

export const BodyBold = styled(Body)`
  font-weight: 700;
`

export const Detail = styled.p`
  ${styles.base};
  ${styles.body};
  font-size: 16px;
`

export const Small = styled.p`
  ${styles.base};
  ${styles.body};
  font-size: 14px;
`

export const DetailBold = styled(Detail)`
  font-weight: 700;
`

export const Quote = styled.h3`
  ${styles.base};
  ${styles.heading};
  font-size: 23px;
`

export const GlobalFontFaces = () => (
  <Global
    styles={css`
      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-Regular.ttf);
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-Italic.ttf);
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-Medium.ttf);
        font-weight: 500;
        font-style: normal;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-MediumItalic.ttf);
        font-weight: 500;
        font-style: italic;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-Bold.ttf);
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-BoldItalic.ttf);
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: PTSerif;
        src: url(/fonts/PTSerif-Regular.ttf);
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: PTSerif;
        src: url(/fonts/PTSerif-Italic.ttf);
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: PTSerif;
        src: url(/fonts/PTSerif-BoldItalic.ttf);
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: PTSerif;
        src: url(/fonts/PTSerif-Bold.ttf);
        font-weight: 700;
        font-style: normal;
      }

      html,
      body {
        box-sizing: border-box;
      }

      body * {
        box-sizing: inherit;
      }
    `}
  />
)
