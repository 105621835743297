import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import color from 'tinycolor2'
import { breakpoints, colors, spacing } from '../../config'
import { Detail } from '../Typography'
import MenuItem from './MenuItem'
import FadeIn from '../FadeIn'

function colorForDepth(area, depth) {
  const baseColor = area == null ? colors.purple : area.color
  return color(baseColor)
    .darken((depth - 1) * 1) // 1% per step
    .toString()
}

function MenuPanel({
  title,
  items,
  onItemClick,
  depth = 0,
  selectedItem,
  HeaderComponent,
  FooterComponent,
  collapsed,
  onExpand,
  area,
}) {
  const renderComponent = (Component) => {
    if (!Component) {
      return null
    }

    // Allows component to be passed as `Component` or with props `<Component prop={1} />`
    if (typeof Component === 'object') {
      return Component
    } else {
      return <Component />
    }
  }

  if (collapsed) {
    return (
      <CollapsedPanel
        bgColor={colorForDepth(area, depth)}
        depth={depth}
        onClick={onExpand}
        className="collapsed"
      >
        <HeaderArea collapsed={collapsed}>
          {renderComponent(HeaderComponent)}
        </HeaderArea>
        <CollapsedTitle>{title}</CollapsedTitle>
      </CollapsedPanel>
    )
  }

  const key =
    items != null && items.length > 0
      ? `panel-${items.length}-${items[0].id}`
      : `panel-${title}`

  return (
    <PanelContainer
      depth={depth}
      className="expanded"
      bgColor={colorForDepth(area, depth)}
      key={key}
    >
      <HeaderArea collapsed={collapsed}>
        {renderComponent(HeaderComponent)}
      </HeaderArea>
      <PanelContent depth={depth}>
        <Items>
          {/* <MenuItem
                item={item}
                onClick={() => onItemClick(item)}
                inactive={selectedItem != null && selectedItem.item !== item}
              /> */}
          {items.map((item, idx) => {
            return (
              <MenuItem
                key={`item-${idx}`}
                item={item}
                onClick={() => onItemClick(item)}
                inactive={selectedItem != null && selectedItem.item !== item}
              />
            )
          })}
        </Items>
      </PanelContent>
      <FooterArea>{renderComponent(FooterComponent)}</FooterArea>
    </PanelContainer>
  )
}

MenuPanel.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  onItemClick: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,

  /**
   * Changes the background colour. 0-indexed
   */
  depth: PropTypes.number,
}

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.bgColor};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
`

const PanelContent = styled(FadeIn)`
  display: flex;
  flex-direction: column;
  padding: ${spacing.mega}px;
  ${(props) => {
    const desktopPadding = props.depth === 0 ? spacing.mega * 2 : spacing.mega
    const mobilePadding = spacing.xl

    return breakpoints({
      paddingLeft: [
        mobilePadding,
        mobilePadding,
        desktopPadding,
        desktopPadding,
      ],
    })
  }};
`

const Title = styled(Detail)`
  color: ${colors.white};
  white-space: nowrap;
`

const CollapsedPanel = styled(PanelContainer)`
  cursor: pointer;
  position: relative;
  width: 70px;
  min-width: 70px;
  align-items: center;
  background-color: ${(props) => props.bgColor};
`

const CollapsedTitle = styled(Title)`
  transform: rotate(-90deg);
  position: absolute;
  top: 50%;
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.xxl}px;
  ${breakpoints({
    alignItems: ['stretch', 'stretch', 'flex-start', 'flex-start'],
  })};
`

const HeaderArea = styled.div`
  min-height: 36px;

  ${(props) => {
    const desktop = props.collapsed ? 0 : spacing.mega
    const mobile = spacing.m

    return breakpoints({
      marginTop: [spacing.xl, spacing.xl, spacing.mega, spacing.mega],
      marginLeft: [mobile, mobile, desktop, desktop],
    })
  }};

  ${(props) => {
    if (props.collapsed) {
      return `
        display: flex;
        justify-content: center;
      `
    }
  }}
`

const FooterArea = styled.div`
  margin-bottom: ${spacing.mega}px;

  ${breakpoints({
    marginLeft: [spacing.xl, spacing.xl, spacing.mega * 2, spacing.mega * 2],
  })};
`

export default MenuPanel
