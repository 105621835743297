import { createSlice } from '@reduxjs/toolkit'
import { selectArticlesByArea } from './articles'
import { colors } from '../config'

export const slice = createSlice({
  name: 'areas',
  initialState: {
    loading: false,
    loaded: false,
    data: [],
  },
  reducers: {
    fetch: state => {
      state.loading = true
    },
    fetchSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload.map(formatArea)
      state.loaded = true
    },
    fetchFail: state => {
      state.loading = false
    },
  },
})

export const { fetch, fetchSuccess, fetchFail } = slice.actions

export const selectLoading = ({ areas }) => areas.loading
export const selectLoaded = ({ areas }) => areas.loaded
export const selectAreas = ({ areas }) => areas.data.slice(0).reverse()
export const selectArea = ({ areas }, id) => {
  return areas.data.find(area => area.id === id)
}
export const selectAreaBySlug = ({ areas }, slug) => {
  return areas.data.find(area => area.slug === slug)
}
export const selectAreasWithArticles = state => {
  const areas = selectAreas(state)
  const articles = selectArticlesByArea(state)
  return areas.map(area => {
    const areaArticles = articles[area.id]

    return {
      ...area,
      children: areaArticles || [],
    }
  })
}

export default slice.reducer

function formatArea(area) {
  return {
    ...area,
    color: color(area),
  }
}

function color(area) {
  switch (area.slug) {
    case 'valuation':
      return colors.blue
    case 'legal':
      return colors.black
    case 'procedural':
      return colors.green
    default:
      return colors.purple
  }
}
