import React from 'react'
import { H2, Detail as DetailText } from './Typography'
import styled from '@emotion/styled'
import FindOutMore from './FindOutMore'
import { breakpoints, colors, spacing } from '../config'
import PropTypes from 'prop-types'
import useHover from '../helpers/useHover'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectArea } from '../state/areas'
import urlFor from '../helpers/urlFor'

/**
 * AreaCard represents one of the main three categories within the site
 */
const AreaCard = ({ id }) => {
  let [hovering, hoverProps] = useHover()
  const area = useSelector(state => selectArea(state, id))

  return (
    <CardLink to={urlFor(area)} {...hoverProps}>
      <Inner>
        <H2>{area.title.rendered}</H2>
        <Detail>{area.acf.description}</Detail>
        <FindOutMore isActive={hovering} />
      </Inner>
    </CardLink>
  )
}

const CardLink = styled(Link)`
  padding: 0 ${spacing.mega}px;
  cursor: pointer;
  max-width: 100%;
  text-decoration: none;
  &:hover {
    background-color: ${colors.grey};
    text-decoration: none;
  }

  ${breakpoints({
    marginTop: [spacing.xxl, spacing.xxl, spacing.mega],
  })};
`

const Inner = styled.div`
  padding: ${spacing.xxl}px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const Detail = styled(DetailText)`
  margin: ${spacing.l}px 0;
  color: ${colors.lightText};
`

AreaCard.propTypes = {
  id: PropTypes.number.isRequired,
}

export default AreaCard
