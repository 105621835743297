import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { breakpoints, colors, spacing } from '../../config'
import ClearButton from '../ArticleGrid/ClearButton'
import { closeSearch } from '../../state/ui'
import { useDispatch } from 'react-redux'
import CloseButton from '../CloseButton'

const SearchBar = ({ query, setQuery }) => {
  const dispatch = useDispatch()

  return (
    <Wrapper>
      <Bar>
        <Input
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder={'Search'}
          autoFocus
          maxlength={32}
        />
        {query.length > 0 && (
          <ClearContainer>
            <ClearButton onClick={() => setQuery('')} />
          </ClearContainer>
        )}
        <CloseButton onClick={() => dispatch(closeSearch())}>
          Cancel
        </CloseButton>
      </Bar>
    </Wrapper>
  )
}

SearchBar.propTypes = {
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
}

const Wrapper = styled.div`
  background-color: white;
`

const Bar = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.grey};
  ${breakpoints({
    margin: [0, 0, `0 ${spacing.xs}px`],
    height: ['85px', '85px', '110px', '110px'],
  })}
  overflow: hidden;
`

const Input = styled.input`
  ${props => {
    const desktopSpace = `${spacing.xxl}px ${spacing.mega}px;`
    const mobileSpace = `${spacing.l}px ${spacing.xl}px;`

    return breakpoints({
      padding: [mobileSpace, mobileSpace, desktopSpace],
      fontSize: [23, 23, 25],
    })
  }}
  flex: 1;
  border: none;
  font-family: PTSerif;
  color: ${colors.black};
  outline: none;
  max-width: calc(100% - 95px);
`

const ClearContainer = styled.div`
  padding: 0 ${spacing.l}px;
  ${breakpoints({
    display: ['none', 'none', 'block'],
  })}
`

export default SearchBar
