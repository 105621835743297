import React from 'react'
import styled from '@emotion/styled'
import { colors, spacing } from '../config'
import { Detail } from './Typography'
import { ReactComponent as ArrowRightIcon } from '../assets/arrow-right.svg'

const DragToSeeMore = ({
  leftEnabled,
  rightEnabled,
  onLeft,
  onRight,
  isVertical,
  upEnabled,
  downEnabled,
  onUp,
  onDown,
}) => {
  onLeft = onLeft || onUp
  onRight = onRight || onDown
  rightEnabled = rightEnabled || downEnabled
  leftEnabled = leftEnabled || upEnabled

  return (
    <ScrollMessage>
      <MobileHint>Drag to see more</MobileHint>
      <ArrowWrapper isVertical={isVertical}>
        <ArrowLeft
          isVertical={isVertical}
          enabled={leftEnabled}
          onClick={onLeft}
        />
        <ArrowRight
          isVertical={isVertical}
          enabled={rightEnabled}
          onClick={onRight}
        />
      </ArrowWrapper>
    </ScrollMessage>
  )
}

const ScrollMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: ${spacing.m}px ${spacing.xl}px;
`

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isVertical ? 'column' : 'row')};
`

const ArrowLeft = styled(({ enabled, isVertical, ...props }) => (
  <ArrowRightIcon {...props} />
))`
  transform: ${props =>
    props.isVertical ? 'rotate(-90deg)' : 'rotate(-180deg)'};
  margin-right: ${props => (props.isVertical ? 0 : spacing.xs)}px;
  margin-bottom: ${props => (props.isVertical ? spacing.xs : 0)}px;
  cursor: pointer;

  path {
    fill: ${props => (props.enabled ? colors.black : colors.black + '88')};
    transition: color 0.15s linear;
  }
`

const ArrowRight = styled(({ enabled, isVertical, ...props }) => (
  <ArrowRightIcon {...props} />
))`
  transform: ${props => (props.isVertical ? 'rotate(90deg)' : 'unset')};
  cursor: pointer;
  path {
    fill: ${props => (props.enabled ? colors.black : colors.black + '88')};
    transition: color 0.15s linear;
  }
`

const MobileHint = styled(Detail)`
  margin-right: ${spacing.xl}px;
`

export default DragToSeeMore
