import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Body, BodyBold, H2 } from '../Typography'
import { breakpoints, colors, spacing } from '../../config'
import { Link } from 'react-router-dom'
import urlFor from '../../helpers/urlFor'
import { useSelector } from 'react-redux'
import { selectKeyPathWithArea } from '../../state/articles'

function SearchResult({ article, onClick }) {
  const breadcrumb = useSelector(state =>
    selectKeyPathWithArea(state, article.id)
  )

  const breadcrumbText = breadcrumb.map(i => i.title.rendered).join(' > ')

  return (
    <Wrapper>
      <ResultLink onClick={onClick} to={urlFor(article)} className={'result'}>
        <Breadcrumb>{breadcrumbText}</Breadcrumb>
        <ArticleDetail>
          <ArticleTitle>{article.title.rendered}</ArticleTitle>
          <ReadMore>Read More</ReadMore>
        </ArticleDetail>
      </ResultLink>
    </Wrapper>
  )
}

SearchResult.propTypes = {
  article: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

const Wrapper = styled.div`
  background-color: white;
`

const ResultLink = styled(Link)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  background-color: white;

  ${props => {
    const desktopSpace = `${spacing.xxl}px ${spacing.mega}px;`
    const mobileSpace = `${spacing.l}px ${spacing.xl}px;`

    return breakpoints({
      padding: [mobileSpace, mobileSpace, desktopSpace],
      fontSize: [23, 23, 25],
    })
  }}
`

const Breadcrumb = styled(Body)``

const ArticleDetail = styled.div`
  display: flex;
  align-items: baseline;

  ${breakpoints({
    flexDirection: ['column', 'column', 'row'],
  })}
`

const ArticleTitle = styled(H2)`
  color: ${colors.black};
  flex: 1;
`

const ReadMore = styled(BodyBold)`
  color: ${colors.black}88;
`

export default SearchResult
