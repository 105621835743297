import 'react-app-polyfill/ie11'
import development from './development'
import production from './production'
import spacing from './spacing'
import colors from './colors'
import breakpoints from './breakpoints'

const DEFAULTS = {
  version: '0.0.1',
  domain: 'https://stupefied-williams-08fc79.netlify.com',
  api: {
    baseURL: 'https://wadiastaging.wpengine.com/wp-json/wp/v2/',
  },
}

let config = {
  ...DEFAULTS,
}

if (process.env.NODE_ENV === 'production') {
  config = {
    ...config,
    ...production,
  }
} else {
  config = {
    ...config,
    ...development,
  }
}

export { spacing, colors, breakpoints }
export default config
