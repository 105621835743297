import facepaint from 'facepaint'
/*
 * Media query breakpoints, when using it use an array of 4 styles for the sizes below
 * Docs - https://github.com/emotion-js/facepaint#styled-components
 */
export default facepaint([
  '@media(min-width: 420px)',
  '@media(min-width: 920px)',
  '@media(min-width: 1120px)',
  '@media(min-width: 1600px)',
  '@media(min-width: 1921px)',
])
