import React, { useState, useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
require('intersection-observer')

export default function FadeIn({
  children,
  step = 0,
  multipleFades,
  ...props
}) {
  const [isVisible, setVisible] = useState(false)
  const ref = useRef()

  useEffect(() => {
    const el = ref.current
    const observer = new window.IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting || multipleFades) {
          setVisible(entry.isIntersecting)
        }
      })
    })
    observer.observe(el)

    return () => observer.unobserve(el)
  }, [ref, multipleFades])

  const delayStyle = css`
    transition-delay: ${step * 0.15}s;
  `

  return (
    <div
      css={[
        styles.wrapper,
        delayStyle,
        isVisible ? styles.visible : null,
        props.css,
      ]}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  )
}

FadeIn.propTypes = {
  step: PropTypes.number, // Increases delay
  children: PropTypes.node,
  multipleFades: PropTypes.bool, // If true, the element can fade in more than once
}

const styles = {
  wrapper: css`
    opacity: 0;
    transform: translateY(30px);
    visibility: hidden;
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    will-change: opacity, visibility;
  `,
  visible: css`
    opacity: 1;
    transform: none;
    visibility: visible;
  `,
}
