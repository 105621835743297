import { useEffect, useState } from 'react'

export default function useWindowSize() {
  function calculateSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  const [size, setSize] = useState(calculateSize())

  useEffect(() => {
    const resizeListener = function() {
      setSize(calculateSize())
    }

    window.addEventListener('resize', resizeListener)

    return () => window.removeEventListener('resize', resizeListener)
  }, [])

  return size
}
