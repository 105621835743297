import React from 'react'
import styled from '@emotion/styled'
import { BodyBold } from '../../components/Typography'
import { colors, spacing } from '../../config'
import { ReactComponent as ASILLogo } from '../../assets/logo-ASIL.svg'
import { ReactComponent as ICCALogo } from '../../assets/logo-ICCA.svg'
import { useSelector } from 'react-redux'
import { selectPages } from '../../state/pages'
import urlFor from '../../helpers/urlFor'
import { Link } from 'react-router-dom'
import { closeMenu } from '../../state/ui'
import { useDispatch } from 'react-redux'

function Footer() {
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)
  const onClose = () => {
    dispatch(closeMenu())
  }

  return (
    <Column>
      {pages.map(page => (
        <LinkWrapper to={urlFor(page)} onClick={() => onClose()} key={page.id}>
          <LinkText>{page.title.rendered}</LinkText>
        </LinkWrapper>
      ))}
      <LinkWrapper onClick={() => onClose()} to={'/'}>
        <ICCALogo />
      </LinkWrapper>
      <LinkWrapper onClick={() => onClose()} to={'/'}>
        <ASILLogo />
      </LinkWrapper>
    </Column>
  )
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const LinkWrapper = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  margin-bottom: ${spacing.l}px;

  &:hover {
    color: ${colors.white}88;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const LinkText = styled(BodyBold)`
  color: ${colors.white};
`

export default Footer
