import { useLayoutEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

if (window.ResizeObserver == null) {
  window.ResizeObserver = ResizeObserver
}

export default function useElementSize() {
  const elementRef = useRef()
  const [elementSize, setElementSize] = useState({})

  const handleResize = () => {
    const size = elementRef.current.getBoundingClientRect().toJSON()
    setElementSize({ width: size.width, height: size.height })
  }

  useLayoutEffect(() => {
    const node = elementRef.current
    if (node == null) {
      return
    }

    handleResize()

    const resizeObserver = new ResizeObserver(function() {
      handleResize()
    })
    resizeObserver.observe(node)

    return () => resizeObserver.disconnect(node)
  }, [])

  return [elementSize, elementRef]
}
