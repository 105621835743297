import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as Expand } from '../../assets/chevron-right.svg'
import { colors, spacing } from '../../config'
import { H4 } from '../Typography'
import urlFor from '../../helpers/urlFor'
import { Link } from 'react-router-dom'
import { css } from '@emotion/core'

function MenuItem({ item, inactive, onClick }) {
  const hasChildren = (item.children?.length || 0) > 0
  const isLink = !hasChildren
  const to = isLink && urlFor(item)
  const Container = isLink && to !== null ? MenuLink : Item

  return (
    <Container to={to} onClick={onClick} inactive={inactive}>
      <Title className="menu-item-title" inactive={inactive}>
        {item.title.rendered}
      </Title>
      {hasChildren && (
        <ExpandIcon className="menu-item-icon" inactive={inactive} />
      )}
    </Container>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    children: PropTypes.array,
    title: PropTypes.shape({
      rendered: PropTypes.string,
    }),
  }).isRequired,
  inactive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

const INACTIVE_COLOR = colors.white + '88' // 50% opacity
const ACTIVE_COLOR = colors.white

const wrapperStyle = props => css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.xxl}px;
  cursor: pointer;
  min-width: 214px;
  max-width: 100%;

  &:hover .menu-item-title {
    color: ${props.inactive ? ACTIVE_COLOR : INACTIVE_COLOR};
  }

  &:hover .menu-item-icon path {
    stroke: ${props.inactive ? ACTIVE_COLOR : INACTIVE_COLOR};
  }
`

const Item = styled.div`
  ${props => wrapperStyle(props)};
`

const MenuLink = styled(Link)`
  text-decoration: none;
  ${props => wrapperStyle(props)};
`

const Title = styled(H4)`
  color: ${props => (props.inactive ? INACTIVE_COLOR : ACTIVE_COLOR)};
`

const ExpandIcon = styled(({ inactive, ...props }) => <Expand {...props} />)`
  margin-left: ${spacing.l}px;
  min-height: 14px;
  height: 14px;
  min-width: 9px;
  width: 9px;

  path {
    stroke: ${props => (props.inactive ? INACTIVE_COLOR : ACTIVE_COLOR)};
  }
`

export default MenuItem
