import { css, Global } from '@emotion/core'
import React from 'react'

export const GlobalStyling = () => (
  <Global
    styles={css`
      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-Regular.ttf);
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-Italic.ttf);
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-Medium.ttf);
        font-weight: 500;
        font-style: normal;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-MediumItalic.ttf);
        font-weight: 500;
        font-style: italic;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-Bold.ttf);
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: DMSans;
        src: url(/fonts/DMSans-BoldItalic.ttf);
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: PTSerif;
        src: url(/fonts/PTSerif-Regular.ttf);
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: PTSerif;
        src: url(/fonts/PTSerif-Italic.ttf);
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: PTSerif;
        src: url(/fonts/PTSerif-BoldItalic.ttf);
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: PTSerif;
        src: url(/fonts/PTSerif-Bold.ttf);
        font-weight: 700;
        font-style: normal;
      }

      html,
      body,
      #root {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        scroll-behavior: smooth;
      }

      body * {
        box-sizing: inherit;
      }
    `}
  />
)
