import React from 'react'
import { breakpoints, colors, spacing } from '../../config'
import { Detail } from '../Typography'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from '../../assets/search.svg'
import { ReactComponent as KeyIcon } from '../../assets/key.svg'
import { ReactComponent as Chevron } from '../../assets/chevron-right.svg'
import {
  openKey,
  openSearch,
  selectCurrentArea,
  selectShowCallout,
} from '../../state/ui'
import NavLeft from './NavLeft'
import Callout from './Callout'
import { useDispatch, useSelector } from 'react-redux'
import color from 'tinycolor2'
import { Link } from 'react-router-dom'

function darkenColor(bgColor) {
  const baseColor = bgColor == null ? colors.purple : bgColor
  return color(baseColor).darken(2).toString()
}

/**
 * NavBar
 *
 */
const NavBar = ({ background, breadcrumbs }) => {
  const dispatch = useDispatch()
  const area = useSelector(selectCurrentArea)
  if (background == null) {
    background = area?.color
  }
  const showCallout = useSelector(selectShowCallout)
  const hoverColor = darkenColor(background)
  return (
    <Wrapper background={background}>
      <Brand hoverColor={hoverColor} />
      <Breadcrumbs>
        {breadcrumbs != null &&
          breadcrumbs.map((breadcrumb, i) => {
            const LinkToArticle =
              breadcrumb.content !== false || breadcrumb.url.includes('/area')
                ? breadcrumb.url
                : null
            return (
              <BreadcrumbWrapper key={`breadcrumb-${i}`}>
                <Breadcrumb to={LinkToArticle}>
                  <Title>{breadcrumb.title}</Title>
                  <Subtitle>{breadcrumb.subtitle}</Subtitle>
                </Breadcrumb>
                {breadcrumbs.length !== i + 1 && <Chevron />}
              </BreadcrumbWrapper>
            )
          })}
      </Breadcrumbs>
      <ActionsWrapper>
        <Button
          hoverColor={hoverColor}
          showMobile
          onClick={() => dispatch(openSearch())}
        >
          <SearchIcon />
          <Label>Search</Label>
        </Button>
        <Divider />
        {/* <Button hoverColor={hoverColor} onClick={() => dispatch(openKey())}>
          <Key />
          <Label>Key</Label>
        </Button> */}
        {showCallout && <KeyCallout />}
      </ActionsWrapper>
    </Wrapper>
  )
}

NavBar.propTypes = {
  background: PropTypes.string,
  breadcrumbs: PropTypes.array,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${breakpoints({
    height: ['80px', '85px', '110px', '110px'],
  })};
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: ${(props) => props.background || colors.purple};
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
`

const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    breakpoints({
      display: [
        props.showMobile ? 'flex' : 'none',
        props.showMobile ? 'flex' : 'none',
        'flex',
      ],
      height: ['83px', '83px', '110px', '110px'],
      width: ['83px', '83px', '110px', '110px'],
    })};

  &:hover {
    background-color: ${(props) => props.hoverColor};
    box-shadow: 0 2px 0 ${colors.white};
  }
`

const Divider = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  width: 1px;
  height: 100%;
  ${breakpoints({
    display: ['none', 'none', 'flex'],
  })};
`

const Key = styled(KeyIcon)`
  height: 32px;
  width: 25px;
`

const Label = styled(Detail)`
  color: ${colors.white};
  font-weight: 700;
`

const Breadcrumbs = styled.div`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  overflow: hidden;

  ${breakpoints({
    display: ['none', 'none', 'flex'],
  })};
`

const Breadcrumb = styled((props) =>
  props.to == null ? <div {...props} /> : <Link {...props} />
)`
  height: 100%;
  padding: ${spacing.xl}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;

  &:hover p {
    color: ${colors.white}aa;
  }
`

const Title = styled(Detail)`
  color: ${colors.white};
  font-weight: 700;
`

const Subtitle = styled(Detail)`
  color: ${colors.white};
`

const Brand = styled(NavLeft)`
  ${breakpoints({
    borderRight: [
      'none',
      'none',
      '1px solid rgba(255, 255, 255, 0.5)',
      '1px solid rgba(255, 255, 255, 0.5)',
    ],
  })};
`

const KeyCallout = styled(Callout)`
  ${breakpoints({
    display: ['none', 'none', 'flex', 'flex'],
  })};
`

const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
`

export default NavBar
