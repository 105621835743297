import { all, takeLatest } from 'redux-saga/effects'
import fetchArticles from './fetchArticles'
import fetchPages from './fetchPages'
import fetchAreas from './fetchAreas'
import { fetch as fetchArticlesAction } from '../state/articles'
import { fetch as fetchPagesAction } from '../state/pages'
import { fetch as fetchAreasAction } from '../state/areas'
import ApiClient from '../api'

export default function* rootSaga() {
  const api = new ApiClient()

  yield all([
    takeLatest(fetchArticlesAction, fetchArticles, api),
    takeLatest(fetchPagesAction, fetchPages, api),
    takeLatest(fetchAreasAction, fetchAreas, api),
  ])
}
