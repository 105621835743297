import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ReactComponent as Chevron } from '../../assets/chevron.svg'
import { breakpoints, colors, spacing } from '../../config'
import BackButton from '../BackButton'
import { Detail, H1, Mega, Title } from '../Typography'
import ArticleSections from './ArticleSections'
import { useSelector } from 'react-redux'
import { selectCurrentArea } from '../../state/ui'
import urlFor from '../../helpers/urlFor'
import { Link } from 'react-router-dom'
import color from 'tinycolor2'
import FadeIn from '../FadeIn'

function darkenColor(bgColor) {
  const baseColor = bgColor == null ? colors.purple : bgColor
  return color(baseColor)
    .darken(2)
    .toString()
}

/**
 * Article Header at the top of all articles.
 *
 */
const ArticleHeader = ({
  title,
  parentTitle,
  themeTitle,
  article,
  backgroundColor,
}) => {
  const [showSections, setShowSections] = useState(false)
  const area = useSelector(selectCurrentArea)
  const areaUrl = urlFor(area)
  const mobileBackground = darkenColor(backgroundColor)

  return (
    <Container>
      <Header backgroundColor={backgroundColor}>
        <BackLink to={areaUrl}>
          <BackButton />
        </BackLink>
        <Titles>
          <Parent>{parentTitle}</Parent>
          <ArticleTitle>{title}</ArticleTitle>
        </Titles>
        <DesktopBackground color={backgroundColor}>
          {themeTitle}
        </DesktopBackground>
      </Header>
      {article.acf.content?.length > 0 && (
        <MobileBackground
          onClick={() => setShowSections(!showSections)}
          backgroundColor={mobileBackground}
        >
          <MobileMessage>Skip to</MobileMessage>
          <MobileSection>
            {article.acf.content[0].title || 'Introduction'}
          </MobileSection>
          <Chevron />
        </MobileBackground>
      )}
      <Sections isActive={showSections} onClick={() => setShowSections(false)}>
        <ArticleSections article={article} />
      </Sections>
    </Container>
  )
}

ArticleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  parentTitle: PropTypes.string.isRequired,
  themeTitle: PropTypes.string.isRequired,
  article: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
}

const Container = styled.div`
  background-color: transparent;
`

const Header = styled.div`
  position: relative;
  min-height: 340px;
  background-color: ${props => props.backgroundColor || colors.green};
  ${breakpoints({
    zIndex: ['1', '1', '-1', '-1'],
  })};
`

const BackLink = styled(props =>
  props.to == null ? <div {...props} /> : <Link {...props} />
)`
  padding: 20px 30px;
  text-decoration: none;
  display: block;
`

const Titles = styled(FadeIn)`
  ${breakpoints({
    padding: ['60px 36px', '60px 36px', '60px 120px', '60px 120px'],
  })};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 4;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const ArticleTitle = styled(H1)`
  color: ${colors.white};
  ${breakpoints({
    fontSize: ['40px', '40px', '50px', '50px'],
  })};
`

const Parent = styled(Title)`
  color: ${colors.white};
`

const DesktopBackground = styled(Mega)`
  color: ${props => props.color || colors.green};
  ${breakpoints({
    textShadow: [
      'none',
      'none',
      '-1px -1px 0 rgba(255,255,255,0.05), 1px -1px 0 rgba(255,255,255,0.05), -1px 1px 0 rgba(255,255,255,0.05), 1px 1px 0 rgba(255,255,255,0.05)',
      '-1px -1px 0 rgba(255,255,255,0.05), 1px -1px 0 rgba(255,255,255,0.05), -1px 1px 0 rgba(255,255,255,0.05), 1px 1px 0 rgba(255,255,255,0.05)',
    ],
  })};
  white-space: nowrap;
  overflow: hidden;
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const MobileBackground = styled.div`
  ${breakpoints({
    display: ['flex', 'flex', 'none', 'none'],
  })};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.backgroundColor || colors.green};
  padding: ${spacing.xl}px ${spacing.m}px;
  cursor: pointer;
`

const MobileMessage = styled(Detail)`
  color: ${colors.white};
`

const MobileSection = styled(Title)`
  color: ${colors.white};
`

const Sections = styled.div`
  display: ${props => (props.isActive ? 'block' : 'none')};
`

export default ArticleHeader
