import { useEffect } from 'react'

export const ESC_KEY = 27

export default function(keyCode, handler) {
  useEffect(() => {
    const onKeyDown = e => {
      if (e.keyCode === keyCode) {
        handler(e)
      }
    }

    document.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  })
}
