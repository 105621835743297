import React from 'react'
import { colors } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import SectionLink from './SectionLink'
import ShareButton from './ShareButton'
import FadeIn from '../FadeIn'

/**
 * ArticleSections navigation for each part of the article and Sharebutton.
 *
 */
const ArticleSections = ({ article }) => {
  return (
    <Wrapper>
      {article.acf.content?.length > 0 &&
        article.acf.content
          .filter(
            section =>
              section.anchor_title != null && section.anchor_title !== ''
          )
          .map(section => (
            <SectionLink
              title={section.anchor_title}
              key={section.anchor_title}
            />
          ))}
      <ShareButton title={article.title.rendered} />
    </Wrapper>
  )
}

ArticleSections.propTypes = {
  article: PropTypes.object.isRequired,
}

const Wrapper = styled(FadeIn)`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey};
  z-index: 999;
`

export default ArticleSections
