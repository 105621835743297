import React from 'react'
import { Body } from './Typography'
import { colors, spacing } from '../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg'
import useHover from '../helpers/useHover'
import { Link } from 'react-router-dom'

const ARROW_WIDTH = 16
const ARROW_SPACING = spacing.xs

/**
 * FindOutMore link with animated underline and arrow.
 *
 * Hover/active state can be manually controlled by passing a boolean for `isActive` prop.
 *
 * isLight is for white text
 */
const FindOutMore = ({ isActive, isLight }) => {
  let [hovering, hoverProps] = useHover()

  // If isActive prop is not supplied, handle active state automtically
  const externallyControlled = isActive != null
  const internalActive = externallyControlled ? isActive : hovering

  // Light or dark theme
  const theme = isLight ? lightTheme : darkTheme

  if (externallyControlled) {
    hoverProps = null
  }

  return (
    <Wrapper {...hoverProps}>
      <VerticallyCentered>
        <Text theme={theme} isActive={internalActive}>
          Find out more
        </Text>
        <HoverIcon isActive={internalActive} />
      </VerticallyCentered>
      <Underline isActive={internalActive} />
    </Wrapper>
  )
}

FindOutMore.propTypes = {
  /**
   * Manually control active state
   */
  isActive: PropTypes.bool,
}

const lightTheme = {
  text: colors.white,
}
const darkTheme = {
  text: colors.black,
}

const Underline = styled.div`
  width: ${(props) =>
    props.isActive ? `calc(100% - ${ARROW_WIDTH + ARROW_SPACING}px)` : '35px'};
  height: 2px;
  background-color: ${colors.orange};
  margin-top: ${spacing.xs}px;
  transition: width 0.15s linear;
`

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
`

const VerticallyCentered = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled(({ isActive, ...props }) => <Body {...props} />)`
  color: ${(props) =>
    props.isActive ? colors.orange : props.theme.text}!important;
  transition: color 0.15s linear;
  a {
    color: inherit;
    text-decoration: none;
  }
`

const HoverIcon = styled(({ isActive, ...props }) => <ArrowRight {...props} />)`
  margin-left: ${ARROW_SPACING}px;
  width: ${ARROW_WIDTH}px;

  path {
    fill: ${colors.orange};
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    transition: opacity 0.15s linear;
  }
`

export default FindOutMore
