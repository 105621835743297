import React, { useState, useEffect } from 'react'
import { breakpoints, colors, spacing } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { H3 } from '../Typography'
import ArticleCard from '../ArticleCard'
import { Redirect } from 'react-router-dom'
import urlFor from '../../helpers/urlFor'
import { useSelector } from 'react-redux'
import { selectCurrentArea } from '../../state/ui'
import FadeIn from '../FadeIn'

/**
 * ArticleFooter bottom of an article. Shows related articles.
 *
 */
const ArticleSection = ({ title, relatedArticles }) => {
  const [redirectUrl, setRedirectUrl] = useState(null)
  const area = useSelector(selectCurrentArea)

  useEffect(() => {
    setRedirectUrl(null)
  }, [redirectUrl])

  if (redirectUrl != null) {
    return <Redirect to={redirectUrl} />
  }

  return (
    <Container bgColor={area?.color || colors.green}>
      {relatedArticles != null && relatedArticles.length > 0 && (
        <Title>{`Related with ${title}`}</Title>
      )}
      <ArticleWrapper>
        {relatedArticles != null &&
          relatedArticles.map((article, i) => (
            <RelatedArticle key={i} step={i}>
              <ArticleCard
                key={`article-${i}`}
                title={article.title.rendered}
                description={article.description}
                numThemes={article.themeData?.articles.length || 0}
                onReadMore={() => setRedirectUrl(urlFor(article))}
                isLight
              />
            </RelatedArticle>
          ))}
      </ArticleWrapper>
    </Container>
  )
}

ArticleSection.propTypes = {
  title: PropTypes.string.isRequired,
  relatedArticles: PropTypes.array,
}

const Container = styled.div`
  display: block;
  ${breakpoints({
    padding: ['60px 30px', '60px 30px', '60px 120px'],
  })};
  padding: ;
  min-height: 530px;
  background-color: ${(props) => props.bgColor};
`

const ArticleWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.xl}px;
  flex-wrap: wrap;

  ${breakpoints({
    flexDirection: ['column', 'column', 'row'],
  })};
`
const Title = styled(H3)`
  color: ${colors.white};
`
const RelatedArticle = styled(FadeIn)`
  margin-bottom: ${spacing.mega}px;
`

export default ArticleSection
