import { combineReducers, createAction } from '@reduxjs/toolkit'
import articlesReducer, {
  selectLoading as selectLoadingArticles,
} from './articles'
import pagesReducer, { selectLoading as selectLoadingPages } from './pages'
import areasReducer, { selectLoading as selectLoadingAreas } from './areas'
import uiReducer from './ui'

export default combineReducers({
  articles: articlesReducer,
  pages: pagesReducer,
  areas: areasReducer,
  ui: uiReducer,
})

export const selectLoading = state => {
  return (
    selectLoadingAreas(state) ||
    selectLoadingArticles(state) ||
    selectLoadingPages(state)
  )
}

export const dataLoaded = createAction('data_loaded')
