import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'

import { closeKey } from '../../state/ui'
import urlFor from '../../helpers/urlFor'
import { colors } from '../../config'

const position = { x: 0, y: 0 }
const edgeType = 'smoothstep'

const LinkItem = (item) => {
  const dispatch = useDispatch()
  const el = item.item
  return (
    <LinkStyled to={urlFor(el)} onClick={() => dispatch(closeKey())}>
      {el.acf.child_title}
    </LinkStyled>
  )
}

export const generateInitialElements = (chartElements) => {
  let initialElements = []
  const pushNodes = (element, index) => {
    if (element.acf.child_title !== '') {
      initialElements.push([
        {
          id: `${element.id}`,
          type: 'input',
          data: { label: <LinkItem item={element} /> },
          position,
        },
      ])
    }
    const children =
      element.children[0] !== undefined
        ? element.children.map((child) => {
            if (child.id && initialElements[index]) {
              initialElements[index].push(
                {
                  id: `${child.id}`,
                  type: `${child.children}` ? 'input' : 'output',
                  data: {
                    label: <LinkItem item={child} />,
                  },
                  position,
                },
                {
                  id: `e${child.id}`,
                  source: `${element.id}`,
                  target: `${child.id}`,
                  type: edgeType,
                  arrowHeadType: 'arrow',
                }
              )
            }
            if (child.children) {
              child.children.map((grandChild) => {
                initialElements[index].push(
                  {
                    id: `${grandChild.id}`,
                    type: `${grandChild.children}` ? 'input' : 'output',
                    data: {
                      label: <LinkItem item={grandChild} />,
                    },
                    position,
                  },
                  {
                    id: `e${grandChild.id}`,
                    source: `${child.id}`,
                    target: `${grandChild.id}`,
                    type: edgeType,
                    arrowHeadType: 'arrow',
                  }
                )
                if (grandChild.children) {
                  grandChild.children.map((greatGrandChild) => {
                    initialElements[index].push(
                      {
                        id: `${greatGrandChild.id}`,
                        type: `${greatGrandChild.children}`
                          ? 'input'
                          : 'output',
                        data: {
                          label: <LinkItem item={greatGrandChild} />,
                        },
                        position,
                      },
                      {
                        id: `e${greatGrandChild.id}`,
                        source: `${grandChild.id}`,
                        target: `${greatGrandChild.id}`,
                        type: edgeType,
                        arrowHeadType: 'arrow',
                      }
                    )
                    if (greatGrandChild.children) {
                      greatGrandChild.children.map((greatGreatGrandChild) => {
                        initialElements[index].push(
                          {
                            id: `${greatGreatGrandChild.id}`,
                            type: `${greatGreatGrandChild.children}`
                              ? 'input'
                              : 'output',
                            data: {
                              label: <LinkItem item={greatGreatGrandChild} />,
                            },
                            position,
                          },
                          {
                            id: `e${greatGreatGrandChild.id}`,
                            source: `${greatGrandChild.id}`,
                            target: `${greatGreatGrandChild.id}`,
                            type: edgeType,
                            arrowHeadType: 'arrow',
                          }
                        )
                      })
                    }
                  })
                }
              })
            }
          })
        : ''
  }

  chartElements.forEach((element, index) => {
    pushNodes(element, index)
  })
  return initialElements
}

const LinkStyled = styled(Link)`
  padding: 10px;
  text-align: center;
  color: ${colors.lightGrey};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: DMSans;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`
