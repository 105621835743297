import React, { useRef } from 'react'
import SearchBar from './SearchBar'
import SearchResult from './SearchResult'
import useSearch from './useSearch'
import styled from '@emotion/styled'
import { colors, spacing } from '../../config'
import useKeydownHandler, { ESC_KEY } from '../../helpers/useKeydownHandler'
import { closeSearch } from '../../state/ui'
import { useDispatch } from 'react-redux'

const SearchOverlay = () => {
  const [query, setQuery, results] = useSearch()
  const dispatch = useDispatch()
  const overlay = useRef()

  const close = () => {
    dispatch(closeSearch())
  }

  useKeydownHandler(ESC_KEY, close)

  return (
    <Overlay
      ref={overlay}
      onClick={e => {
        if (e.target === overlay.current) {
          close()
        }
      }}
    >
      <SearchBar query={query} setQuery={setQuery} />
      {results.map((article, i) => (
        <SearchResult article={article} onClick={close} />
      ))}
    </Overlay>
  )
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: ${colors.black}44; // 25% opacity

  .result {
    margin: 0 ${spacing.xs}px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .result:last-child {
    border-bottom: none;
  }
`

export default SearchOverlay
