import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ArticleCard from '../ArticleCard'
import { spacing, colors, breakpoints } from '../../config'
import { H3, Detail } from '../Typography'
import ClearButton from './ClearButton'
import useOverflow from '../../helpers/useOverflow'
import DragToSeeMore from '../DragToSeeMore'
import urlFor from '../../helpers/urlFor'
import { Redirect } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'
import FadeIn from '../FadeIn'
import ScrollContainer from 'react-indiana-drag-scroll'
import { css } from '@emotion/core'

const ArticleRow = ({
  scrollOnRender,
  area,
  articles,
  title,
  onSelectArticle,
  onClear,
}) => {
  const [selectedArticle, setSelectedArticle] = useState(null)
  const hasSelectedArticle = selectedArticle != null
  const [
    { overflowing, overflowingLeft, overflowingRight, scrollXBy },
    containerProps,
    innerProps,
  ] = useOverflow(true)
  const [redirectUrl, setRedirectUrl] = useState(null)

  // Scroll to first item when we load the row
  const firstItem = useRef()
  useEffect(() => {
    if (firstItem.current == null || !scrollOnRender) {
      return
    }

    firstItem.current.scrollIntoView()
  }, [firstItem.current, scrollOnRender])

  if (redirectUrl != null) {
    return <Redirect to={redirectUrl} />
  }

  return (
    <Container>
      <TitleRow>
        <Side>
          <Title>{title}</Title>
          {hasSelectedArticle ? (
            <ClearButton
              onClick={() => {
                setSelectedArticle(null)
                if (onClear != null) {
                  onClear()
                }
              }}
            />
          ) : (
            <ArticleCount color={area.color}>{articles.length}</ArticleCount>
          )}
        </Side>
        <Side>
          {overflowing && (
            <ViewMore
              rightEnabled={overflowingRight}
              leftEnabled={overflowingLeft}
              onLeft={() => scrollXBy(-450)}
              onRight={() => scrollXBy(450)}
            />
          )}
        </Side>
      </TitleRow>
      <ArticlesWrapper>
        <Articles {...containerProps}>
          <div
            css={scrollContainerStyle}
            vertical={false}
            hideScrollbars={false}
            onScrollEnd={containerProps.onScroll}
          >
            <InnerScroll {...innerProps}>
              {/* <StartSpacer visible={hasSelectedArticle} /> */}

              {articles.map((article, i) => {
                const ref = i === 0 ? firstItem : null

                return (
                  <Fade key={article.id} step={i}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const isDisabled =
                          hasSelectedArticle && selectedArticle !== article

                        return (
                          <ArticleWrapper
                            ref={ref}
                            key={`article-${i}`}
                            isVisible={!overflowing || isVisible}
                            isDisabled={isDisabled}
                          >
                            <ArticleCard
                              title={article.title.rendered}
                              description={article.description}
                              numThemes={article.children.length}
                              onReadMore={() => setRedirectUrl(urlFor(article))}
                              onSeeThemes={() => {
                                if (article.children.length > 0) {
                                  setSelectedArticle(article)
                                } else {
                                  setSelectedArticle(null)
                                }

                                if (onSelectArticle != null) {
                                  onSelectArticle(article)
                                }
                              }}
                              isSelected={selectedArticle === article}
                              isDisabled={isDisabled}
                            />
                          </ArticleWrapper>
                        )
                      }}
                    </VisibilitySensor>
                  </Fade>
                )
              })}
            </InnerScroll>
          </div>
        </Articles>
      </ArticlesWrapper>
    </Container>
  )
}

ArticleRow.propTypes = {
  area: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        rendered: PropTypes.string.isRequired,
      }),
      description: PropTypes.string,
    })
  ).isRequired,
  onClear: PropTypes.func,
  onSelectArticle: PropTypes.func,
  scrollOnRender: PropTypes.bool,
}

const scrollContainerStyle = css`
  display: flex;
`

const Container = styled.div`
  margin-bottom: ${spacing.l}px;

  &:first-of-type {
    ${breakpoints({
      marginTop: [spacing.xxl, spacing.xxl, spacing.mega],
    })};
  }
`

const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Articles = styled.div`
  padding-bottom: ${spacing.s}px;
  display: flex;
  overflow-y: none;

  ${breakpoints({
    width: ['100%', '100%', 'unset'],
    // overflowX: ['visible', 'visible', 'scroll'],
    touchAction: ['unset', 'unset', 'pan-x'],
    flexDirection: ['column', 'column', 'row'],
    alignItems: ['stretch', 'stretch', 'unset'],
  })}
`

const InnerScroll = styled.div`
  display: flex;
  flex: 1;
  width: auto;
  /* flex-wrap: nowrap; */
  flex-wrap: wrap;

  display: grid;
  row-gap:115px;



  ${breakpoints({
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
  })}

  ${breakpoints({
    marginLeft: [spacing.xl, spacing.xl, spacing.mega * 2],
    marginRight: [spacing.xl, spacing.xl, spacing.mega * 2],
  })}

  ${breakpoints({
    flexDirection: ['column', 'column', 'row'],
  })}
`

const ArticleWrapper = styled.div`
  ${(props) => {
    const mobileDisplay = props.isDisabled ? 'none' : 'unset'

    return breakpoints({
      display: [mobileDisplay, mobileDisplay, 'unset'],
    })
  }}
  opacity: ${(props) => (props.isVisible ? 1 : 0.5)};
  transition: opacity 0.15s linear;
`

const TitleRow = styled.div`
  display: flex;
  margin-bottom: ${spacing.xxl}px;
  justify-content: space-between;

  ${breakpoints({
    marginLeft: [spacing.xl, spacing.xl, spacing.mega * 2],
    marginRight: [spacing.xl, spacing.xl, spacing.mega * 2],
  })}
`

const Side = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled(H3)`
  margin-right: ${spacing.m}px;
  font-weight: 400;

  ${breakpoints({
    fontSize: [22, 22, 25],
  })}
`

const ArticleCount = styled(Detail)`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 12px;
  background-color: ${(props) => props.color || colors.green};
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StartSpacer = styled.div`
  width: 115px;
  min-width: 115px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: ${(props) => (props.visible ? 1 : 0)};

  ${breakpoints({
    display: ['none', 'none', 'block'],
  })};
`

const ViewMore = styled(DragToSeeMore)`
  ${breakpoints({
    display: ['none', 'none', 'none'],
  })};
`

const Fade = styled(FadeIn)`
  ${breakpoints({ marginBottom: [spacing.xxl, spacing.xxl, 0] })}
`

export default ArticleRow
