import { useLayoutEffect, useState } from 'react'
import useElementSize from './useElementSize'
const END_SNAP = 100

export default function useOverflowing(scrollChild = false) {
  const [
    { height: containerHeight, width: containerWidth },
    containerRef,
  ] = useElementSize()
  const [
    { height: innerHeight, width: innerWidth },
    innerRef,
  ] = useElementSize()

  const [overflowing, setOverflowing] = useState(false)
  const [overflowingRight, setOverflowingRight] = useState(false)
  const [overflowingLeft, setOverflowingLeft] = useState(false)
  const [overflowingTop, setOverflowingTop] = useState(false)
  const [overflowingBottom, setOverflowingBottom] = useState(false)
  const scrollableEl = scrollChild
    ? containerRef?.current?.children[0]
    : containerRef.current

  const updateOverflowing = () => {
    const scrollLeft = scrollableEl?.scrollLeft
    const scrollTop = scrollableEl?.scrollTop
    const overflowingHorizontal = containerWidth < innerWidth
    const overflowingVertical = containerHeight < innerHeight

    setOverflowing(overflowingHorizontal || overflowingVertical)
    setOverflowingLeft(scrollLeft > 0)
    setOverflowingRight(
      overflowingHorizontal && innerWidth - containerWidth > scrollLeft
    )
    setOverflowingTop(scrollTop > 0)
    setOverflowingBottom(
      overflowingVertical && innerHeight - containerHeight > scrollTop
    )
  }

  const scrollTop = scrollableEl?.scrollTop || 0
  const scrollLeft = scrollableEl?.scrollLeft || 0

  useLayoutEffect(() => {
    updateOverflowing()
  }, [
    containerHeight,
    innerHeight,
    containerWidth,
    innerWidth,
    setOverflowing,
    setOverflowingLeft,
    setOverflowingRight,
    setOverflowingTop,
    setOverflowingBottom,
    scrollableEl,
    scrollLeft,
    scrollTop,
  ])

  return [
    {
      overflowing,
      overflowingRight,
      overflowingLeft,
      overflowingTop,
      overflowingBottom,
      scrollXBy: (amount = 50) => {
        if (scrollableEl == null) {
          return
        }

        const originalStyle = scrollableEl.style
        scrollableEl.style = `${originalStyle}; scroll-behavior: smooth;`

        // If we're near the ends, just go there
        const finalPosition = scrollableEl.scrollLeft + amount
        if (amount < 0 && finalPosition < END_SNAP) {
          amount -= finalPosition
        } else if (
          amount > 0 &&
          finalPosition > innerWidth - containerWidth - END_SNAP
        ) {
          amount += finalPosition
        }

        scrollableEl.scrollBy(amount, 0)
        setTimeout(() => {
          scrollableEl.style = originalStyle
        }, 250)
      },
      scrollYBy: (amount = 50) => {
        if (scrollableEl == null) {
          return
        }

        const originalStyle = scrollableEl.style
        scrollableEl.style = `${originalStyle}; scroll-behavior: smooth;`

        // If we're near the ends, just go there
        const finalPosition = scrollableEl.scrollTop + amount
        if (amount < 0 && finalPosition < END_SNAP) {
          amount -= finalPosition
        } else if (
          amount > 0 &&
          finalPosition > innerWidth - containerWidth - END_SNAP
        ) {
          amount += finalPosition
        }

        scrollableEl.scrollBy(0, amount)
        setTimeout(() => {
          scrollableEl.style = originalStyle
        }, 250)
      },
    },

    // Container props
    {
      ref: containerRef,
      onScroll: () => {
        updateOverflowing()
      },
    },

    // Inner element props
    { ref: innerRef },
  ]
}
