import React from 'react'
import { breakpoints, colors, spacing } from '../../config'
import { DetailBold, Small } from '../Typography'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { dismissCallout } from '../../state/ui'

/**
 * Callout at top of navbar
 *
 */
const Callout = () => {
  const dispatch = useDispatch()

  return (
    // <Wrapper>
    //   <Arrow />
    //   <Title>Know what you are after?</Title>
    //   <Subtitle>
    //     Use this key to help navigate directly to the desired article.
    //   </Subtitle>
    //   <Button onClick={() => dispatch(dismissCallout())}>Dismiss</Button>
    // </Wrapper>
    <></>
  )
}

const Wrapper = styled.div`
  flex-direction: column;
  justify-content: space-around;
  width: 200px;
  height: 170px;
  text-align: center;
  padding: ${spacing.s}px;
  background-color: ${colors.white};
  position: absolute;
  right: 0;
  z-index: 9999;

  ${breakpoints({
    top: [80, 85, 110],
    display: ['none', 'none', 'flex'],
  })}
`

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  position: absolute;
  top: -5px;
  right: 53px;
`

const Title = styled(DetailBold)`
  color: ${colors.black};
  font-weight: 700;
`

const Subtitle = styled(Small)`
  color: ${colors.black};
`

const Button = styled(DetailBold)`
  color: ${colors.orange};
  cursor: pointer;
`

export default Callout
