import React from 'react'
import { BodyBold } from '../Typography'
import { colors, spacing } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import useOverflow from '../../helpers/useOverflow'
import DragToSeeMore from '../DragToSeeMore'

/**
 * ConnectedList for article. Horizontal scroll list
 *
 */
const ConnectedList = ({ content }) => {
  const [
    { overflowing, overflowingLeft, overflowingRight, scrollXBy },
    containerProps,
    innerProps,
  ] = useOverflow()

  return (
    <Container>
      <ListWrapper {...containerProps}>
        <ScrollArea {...innerProps}>
          {content.map((item, i) => (
            <Row key={`list-item-${i}`}>
              <Spacer count={i} />
              <ListItem>
                <ListContent>{item.title}</ListContent>
                <BarArea>
                  <BarThick />
                  <BarThin />
                </BarArea>
              </ListItem>
            </Row>
          ))}
        </ScrollArea>
      </ListWrapper>
      {overflowing && (
        <DragToSeeMore
          rightEnabled={overflowingRight}
          leftEnabled={overflowingLeft}
          onLeft={() => scrollXBy(-200)}
          onRight={() => scrollXBy(200)}
        />
      )}
    </Container>
  )
}

ConnectedList.propTypes = {
  content: PropTypes.array.isRequired,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.s}px;
`

const ListWrapper = styled.div`
  overflow-x: scroll;
  touch-action: pan-x;
  display: flex;
  scroll-behavior: smooth;
`

const ScrollArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
`

const ListItem = styled.div`
  margin: ${spacing.s}px ${spacing.tiny};
`

const ListContent = styled(BodyBold)`
  margin: ${spacing.s}px ${spacing.tiny}px;
  width: 200px;
`

const Spacer = styled.div`
  width: ${(props) => props.count * 200}px;
  min-width: ${(props) => props.count * 200}px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
`

const BarArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 3px;
  background-color: none;
`

const BarThick = styled.span`
  height: 3px;
  width: 50px;
  background-color: ${colors.black};
`

const BarThin = styled.span`
  height: 1px;
  background-color: ${colors.lightGrey};
  margin-bottom: ${spacing.xl}px;
  width: 100%;
`

export default ConnectedList
