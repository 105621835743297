import React from 'react'
import { Detail, Quote as Text } from '../Typography'
import { colors, spacing, breakpoints } from '../../config'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

/**
 * Quote content for an Article with quote and author
 *
 */
const Quote = ({ quote, author }) => {
  return (
    <Wrapper>
      <Content>{quote}</Content>
      <Author>- {author}</Author>
    </Wrapper>
  )
}

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  anchor: PropTypes.string,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${colors.black};
  margin: ${spacing.s}px 0;
  padding: 0 ${spacing.xl}px;
  ${breakpoints({
    marginLeft: ['30px', '30px', '120px', '120px'],
  })};
`

const Content = styled(Text)`
  display: inline-block;
  color: ${colors.green};
`

const Author = styled(Detail)`
  margin-top: ${spacing.xl}px;
`

export default Quote
