import { createSlice } from '@reduxjs/toolkit'
import { selectArea } from './areas'

export const slice = createSlice({
  name: 'articles',
  initialState: {
    loaded: false,
    loading: false,

    // Data is hierarchical. Each element has an array of `children`
    data: [],

    // Not hierarchical, easier to search
    rawData: [],
  },
  reducers: {
    fetch: state => {
      state.loading = true
    },
    fetchSuccess: (state, action) => {
      state.loading = false
      state.data = formatApiData(action.payload)
      state.rawData = action.payload
      state.loaded = true
    },
    fetchFail: state => {
      state.loading = false
    },
  },
})

export const { fetch, fetchSuccess, fetchFail } = slice.actions

export const selectLoading = state => state.articles.loading
export const selectLoaded = state => state.articles.loaded
export const selectArticles = state => state.articles.data
export const selectFlatArticles = state => state.articles.rawData
export const selectRawArticle = (state, id) => {
  return state.articles.rawData?.find(a => a.id === id)
}
export const selectArticlesForArea = (state, areaId) => {
  return state.articles.data.filter(a => a.acf.area === areaId)
}
export const selectArticlesByArea = state => {
  return state.articles.data.reduce((byArea, articleTree) => {
    const areaId = articleTree.acf.area
    if (byArea[areaId] == null) {
      byArea[areaId] = []
    }
    byArea[areaId].push(articleTree)
    return byArea
  }, {})
}
export const selectArticleBySlug = (state, slug) => {
  return state.articles.rawData.find(article => article.slug === slug)
}
export const selectParent = (state, childId) => {
  if (childId == null) {
    return null
  }

  const child = selectRawArticle(state, childId)
  if (child == null) {
    return null
  }

  const parentId = child.acf.parent
  return state.articles.rawData.find(article => article.id === parentId)
}
export const selectArticleById = (state, id) => {
  return state.articles.rawData.find(article => article.id === id)
}

export const selectRelatedArticles = (state, id) => {
  const article = selectArticleById(state, id)
  if (article == null) {
    return null
  }

  const related = article.acf.related_articles
  if (related == null || related === false) {
    return selectArticleChildren(state, id)
  }

  return related.map(related => selectArticleById(state, related.article))
}
export const selectArticleChildren = (state, id) => {
  return state.articles.rawData.filter(a => a.acf.parent === id)
}
export const selectKeyPath = (state, targetArticleId) => {
  const endArticle = selectRawArticle(state, targetArticleId)
  if (endArticle == null) {
    return []
  }

  let path = [endArticle]

  let currentArticle = endArticle
  while (currentArticle.acf.parent != null) {
    const nextArticle = selectRawArticle(state, currentArticle.acf.parent)
    if (nextArticle == null) {
      return path
    }

    path = [nextArticle, ...path]
    currentArticle = nextArticle
  }

  return path
}
export const selectKeyPathWithArea = (state, targetArticleId) => {
  const keyPath = selectKeyPath(state, targetArticleId)
  if (keyPath.length === 0) {
    return []
  }

  const area = selectArea(state, keyPath[0].acf.area)
  return [area, ...keyPath]
}
export const selectAreaForArticleId = (state, articleId) => {
  const article = selectArticleById(state, articleId)

  if (article == null) {
    return null
  }

  if (article.acf.area != null) {
    return selectArea(state, article.acf.area)
  }

  const keyPath = selectKeyPathWithArea(state, articleId)
  if (keyPath.length === 0) {
    return null
  }

  return keyPath[0]
}

export default slice.reducer

export function formatApiData(apiData) {
  return apiData
    .filter(a => a.acf.parent == null || a.acf.parent === false)
    .map(a => {
      return formatArticle(a, apiData)
    })
}

function formatArticle(article, apiData, parent) {
  return {
    ...article,
    description: extractDescription(article),
    parent,
    children: apiData
      .filter(a => a.acf.parent === article.id)
      .map(a => {
        return formatArticle(a, apiData, article)
      }),
  }
}

function extractDescription(article) {
  const content = article.acf.content
  if (!content) {
    return null
  }

  const firstTextContent = content.find(c => c.acf_fc_layout === 'text')
  if (firstTextContent == null) {
    return null
  }

  return stripHtml(firstTextContent.text).substr(0, 140)
}

function stripHtml(html) {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  let res = tmp.textContent || tmp.innerText || ''
  res.replace('\u200B', '') // zero width space
  res = res.trim()
  return res
}
