import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as Brand } from '../assets/brand-icon.svg'
import { colors, spacing } from '../config'
import { Detail } from './Typography'

function Loading({ onClick }) {
  return (
    <Container>
      <Icon />
      <Text>Loading</Text>
    </Container>
  )
}

Loading.propTypes = {
  onClick: PropTypes.func,
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.green};
`

const Icon = styled(Brand)`
  height: 40px;
  padding-left: ${spacing.xs}px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
`

const Text = styled(Detail)`
  margin-top: ${spacing.l}px;
  color: ${colors.white};
`

export default Loading
