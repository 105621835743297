import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import ArticleGrid from '../components/ArticleGrid'
import Loading from '../components/Loading'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { selectAreaBySlug, selectAreas, selectLoaded } from '../state/areas'
import { currentAreaId } from '../state/ui'

const Area = () => {
  const dispatch = useDispatch()
  let { slug } = useParams()
  const area = useSelector(state => selectAreaBySlug(state, slug))
  const isLoaded = useSelector(selectLoaded)
  const navItems = useSelector(state => selectAreas(state))
  let [is404, setIs404] = useState(false)

  useEffect(() => {
    setIs404(area == null && isLoaded)
    dispatch(currentAreaId(area?.id))
  }, [dispatch, area, isLoaded])

  if (is404) {
    return <Redirect to={'/404'} />
  }
  if (area == null) {
    return <Loading />
  }

  return (
    <div key={`area-${area.slug}`}>
      <Helmet>
        <title>DIA | {area.title.rendered}</title>
        <meta property={area.acf.description} />
      </Helmet>
      <NavBar background={area.color} />
      <PageHeader
        title={'Damages in International Arbitration'}
        navItems={navItems}
        selectedAreaId={area.id}
        background={area.color}
      />
      <ArticleGrid area={area} />
    </div>
  )
}

export default Area
