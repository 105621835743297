import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { breakpoints, colors, spacing } from '../config'
import { ReactComponent as CloseIcon } from '../assets/menu-close.svg'
import { DetailBold } from './Typography'

const CloseButton = ({ onClick }) => {
  return (
    <CancelButtonArea onClick={onClick}>
      <Cross />
      <CancelText>Cancel</CancelText>
    </CancelButtonArea>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
}

const CancelButtonArea = styled.div`
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  ${breakpoints({ padding: [spacing.m, spacing.m, spacing.xl] })}}
`

const Cross = styled(CloseIcon)`
  height: 30px;

  line {
    stroke: ${colors.black};
  }

  ${breakpoints({
    marginBottom: [spacing.xs, spacing.xs, spacing.m],
  })}
`

const CancelText = styled(DetailBold)`
  color: ${colors.black};
`

export default CloseButton
