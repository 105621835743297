import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ArticleRow from './ArticleRow'
import { selectArticlesForArea } from '../../state/articles'
import { useSelector } from 'react-redux'
import FadeIn from '../FadeIn'

const ArticleGrid = ({ area }) => {
  const [selections, setSelections] = useState([])
  const topLevelArticles = useSelector(state =>
    selectArticlesForArea(state, area.id)
  )
  const firstRow = { title: area.acf.child_title, articles: topLevelArticles }
  const rows = [firstRow, ...selections]

  return (
    <Container>
      {rows.map((row, depth) => (
        <ArticleRow
          scrollOnRender={depth > 0}
          area={area}
          key={`row-${depth}`}
          title={row.title}
          articles={row.articles}
          onClear={() => {
            setSelections(selections.slice(0, depth))
          }}
          onSelectArticle={article => {
            if (article.children.length === 0) {
              setSelections(selections.slice(0, depth))
              return
            }

            setSelections([
              ...selections.slice(0, depth),
              { title: article.acf.child_title, articles: article.children },
            ])
          }}
        />
      ))}
    </Container>
  )
}

ArticleGrid.propTypes = {
  area: PropTypes.object.isRequired,
}

const Container = styled(FadeIn)`
  display: flex;
  flex-direction: column;
`

export default ArticleGrid
