import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { colors, breakpoints } from '../config'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import NavBar from '../components/NavBar'
import {
  selectArticleBySlug,
  selectRelatedArticles,
  selectLoaded,
  selectParent,
  selectAreaForArticleId,
  selectKeyPath,
} from '../state/articles'
import { currentArticleId, currentAreaId } from '../state/ui'
import ArticleHeader from '../components/Article/ArticleHeader'
import ArticleBody from '../components/Article/ArticleBody'
import OverviewButton from '../components/Article/OverviewButton'
import ArticleSections from '../components/Article/ArticleSections'
import ArticleFooter from '../components/Article/ArticleFooter'
import Loading from '../components/Loading'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import urlFor from '../helpers/urlFor'
import useWindowSize from '../helpers/useWindowSize'

const Article = () => {
  let { slug } = useParams()
  const dispatch = useDispatch()
  const article = useSelector((state) => selectArticleBySlug(state, slug))
  const isLoaded = useSelector(selectLoaded)
  const parent = useSelector((state) => selectParent(state, article?.id))
  const relatedArticles = useSelector((state) =>
    selectRelatedArticles(state, article?.id)
  )
  const area = useSelector((state) =>
    selectAreaForArticleId(state, article?.id)
  )
  let keyPath = useSelector((state) => selectKeyPath(state, article?.id))
  const { width } = useWindowSize()
  let maxBreadcrumbs = 6
  if (width < 920) {
    maxBreadcrumbs = 1
  } else if (width < 1120) {
    maxBreadcrumbs = 2
  } else if (width < 1600) {
    maxBreadcrumbs = 3
  }
  keyPath = keyPath.slice(Math.max(keyPath.length - maxBreadcrumbs + 1, 0))

  let [is404, setIs404] = useState(false)

  useEffect(() => {
    setIs404(article == null && isLoaded)
    dispatch(currentArticleId(article?.id))
    dispatch(currentAreaId(area?.id))
  }, [dispatch, article, isLoaded, area])

  if (is404) {
    return <Redirect to={'/404'} />
  }

  if (article == null) {
    return <Loading />
  }

  const breadcrumbs = [
    {
      title: area?.title.rendered,
      subtitle: 'Overview',
      url: urlFor(area),
      item: area,
    },
    ...keyPath.map((article) => ({
      title: article.title.rendered,
      subtitle: null,
      content: article.acf.content,
      url: urlFor(article),
      item: article,
    })),
  ]

  for (let i = 1; i < breadcrumbs.length; i++) {
    breadcrumbs[i].subtitle = breadcrumbs[i - 1].item.acf
      ? breadcrumbs[i - 1].item.acf.child_title
      : ''
  }

  return (
    <div>
      <Helmet>
        <title>DIA | {article.title.rendered}</title>
      </Helmet>
      <NavBar background={area?.color} breadcrumbs={breadcrumbs} />
      <ArticleHeader
        title={article.title.rendered}
        article={article}
        parentTitle={parent?.acf.child_title}
        themeTitle={article.title.rendered}
        backgroundColor={area?.color}
      />
      <Container>
        <StickyContainer>
          <Sticky>
            <OverviewButton />
          </Sticky>
        </StickyContainer>
        <ArticleBody article={article} />
        <Spacer>
          <SectionsNav>
            <ArticleSections article={article} />
          </SectionsNav>
        </Spacer>
      </Container>
      <ArticleFooter
        title={article.title.rendered}
        relatedArticles={relatedArticles}
      />
    </div>
  )
}

const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  min-height: 230px;

  ${breakpoints({
    padding: ['0', '0', '50px 120px 0'],
  })};
`

const StickyContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`

const Sticky = styled(StickyBox)`
  left: 0;
  height: 230px;
`

const SectionsNav = styled.div`
  margin: -64px auto 0 auto;
  z-index: 6;
  ${breakpoints({
    display: ['none', 'none', 'block', 'block'],
  })};
  background-color: white;
`

const Spacer = styled.div`
  width: 100%;
  text-align: center;
  ${breakpoints({
    display: ['none', 'none', 'flex'],
  })};
`

export default Article
